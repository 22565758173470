import { TOGGLE_MODAL, SET_UI_STATE } from '../actions/uiActions'

const initialState = {
  toggleLoading: () => void 0,
  // ERROR MODAL
  errorModalOpen: false,
  errorMessage: '',
  errorAction: () => void 0,
  // CONFIRM MODAL
  confirmModalOpen: false,
  confirmMessage: '',
  confirmAction: () => void 0,
  // EDIT CLINIC MODAL
  clinicEditModalOpen: false,
  clinicToEdit: {},
  // ADD USER MODAL
  createUserModalOpen: false,
  // SWAP ID MODAL
  swapIdModalOpen: false,
  // EDIT ID MODAL
  editIdModalOpen: false,
  jobToEdit: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      let stateUpdate = {}
      if (action.modal === 'error')
        stateUpdate.errorModalOpen = !state.errorModalOpen
      else if (action.modal === 'confirm')
        stateUpdate.confirmModalOpen = !state.confirmModalOpen
      else if (action.modal === 'editClinic')
        stateUpdate.clinicEditModalOpen = !state.clinicEditModalOpen
      else if (action.modal === 'createUser')
        stateUpdate.createUserModalOpen = !state.createUserModalOpen
      else if (action.modal === 'swapId')
        stateUpdate.swapIdModalOpen = !state.swapIdModalOpen
      else if (action.modal === 'editId')
        stateUpdate.editIdModalOpen = !state.editIdModalOpen
      return {
        ...state,
        ...stateUpdate
      }
    case SET_UI_STATE:
      action.type = undefined
      return {
        ...state,
        ...action
      }
    default:
      return state
  }
}

export default reducer
