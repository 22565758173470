import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import Dropdown from '../../../ui-elements/Dropdown'

// ACTIONS
import { setFilter } from '../../../../redux/actions/clinicActions'

const FilterUsers = () => {
   const dispatch = useDispatch()
   const activeFilter =
      useSelector(state => state.clinics.activeFilter) === 'inactive'

   const handleChange = value => {
      dispatch(setFilter({ userFilter: value ?? 'all' }))
   }

   const userOptions = useSelector(state => state.users.userOptions)
   const userFilter = useSelector(state => state.clinics.userFilter)
   const allUsers = userFilter === 'all'
   const value = allUsers ? { name: 'Alle Nutzer' } : userFilter
   return (
      <Dropdown
         options={userOptions}
         value={value}
         onChange={handleChange}
         labelText='Sachbearbeiter'
         labelField='name'
         valueField='userId'
         isDisabled={activeFilter}
         isClearable={!allUsers}
         isSearchable={false}
         width='90%'
         zIndex={100}
      />
   )
}

export default FilterUsers
