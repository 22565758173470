import axios from "axios"

// HELPER
import { handleHttpError } from "../../helper/helper"

// ACTIONS
import { getUserOptions } from "./userActions"
import { getClinicsInformation } from "../../helper/helper"

export const SET_CLINIC_STATE = "SET_CLINIC_STATE"
export const UPDATE_CLINIC = "UPDATE_CLINIC"
export const UPDATE_CLINICS_INFO = "UPDATE_CLINICS_INFO"
export const APPLY_FILTERS = "APPLY_FILTERS"
export const RESET_FILTERS = "RESET_FILTERS"

export const getClinics = (toggleLoading = () => void 0) => {
  return async (dispatch, getState) => {
    toggleLoading()
    try {
      const { data: clinics } = await axios.get(
        process.env.REACT_APP_API_ROUTE + `/clinics/get-clinics`,
        { headers: { Authorization: "Bearer " + getState().users.token } }
      )
      dispatch(getUserOptions())
      dispatch({
        type: SET_CLINIC_STATE,
        payload: {
          clinics,
          clinicsInformation: getClinicsInformation(clinics),
        },
      })
      dispatch({ type: RESET_FILTERS })
      dispatch({ type: APPLY_FILTERS })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
  }
}

export const getUserClinics = (toggleLoading) => {
  return async (dispatch, getState) => {
    toggleLoading()
    try {
      const { data: clinics } = await axios.get(
        process.env.REACT_APP_API_ROUTE +
        `/clinics/get-user-clinics/${getState().users.userId}`,
        { headers: { Authorization: "Bearer " + getState().users.token } }
      )
      dispatch({
        type: SET_CLINIC_STATE,
        payload: {
          clinics,
          clinicsInformation: getClinicsInformation(clinics),
        },
      })
      dispatch({ type: RESET_FILTERS })
      dispatch({ type: APPLY_FILTERS })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
  }
}

export const updateClinic = (update, toggleLoading = () => void 0) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_ROUTE + "/clinics/update-clinic",
        update,
        { headers: { Authorization: "Bearer " + getState().users.token } }
      )
      const clinic = response.data
      clinic.show = true
      dispatch({ type: UPDATE_CLINIC, clinic })
      if ("customer" in update.update)
        dispatch({
          type: UPDATE_CLINICS_INFO,
          updates: { nCustomers: clinic.customer ? 1 : -1 },
        })
      if ("active" in update.update)
        dispatch({
          type: UPDATE_CLINICS_INFO,
          updates: {
            nActive: clinic.active ? 1 : -1,
            nInaktive: clinic.active ? -1 : 1,
          },
        })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
  }
}

export const crawlClinic = (clinicId, toggleLoading, reload = null) => {
  return async (dispatch, getState) => {
    toggleLoading()
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ROUTE + "/clinics/crawl-clinic",
        { clinicId },
        { headers: { Authorization: "Bearer " + getState().users.token } }
      )
      if (!reload) {
        const clinic = response.data
        clinic.show = true
        dispatch({ type: UPDATE_CLINIC, clinic })
      }
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
    if (reload) {
      reload()
    }
  }
}

export const getClinicOptions = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_ROUTE + "/clinics/get-clinic-options",
        { headers: { Authorization: "Bearer " + getState().users.token } }
      )
      dispatch({
        type: SET_CLINIC_STATE,
        payload: { clinicOptions: response.data },
      })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
  }
}

export const setFilter = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_CLINIC_STATE, payload })
    dispatch({ type: APPLY_FILTERS })
  }
}
