import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import AppearTransition from '../../AppearTransition'
import PageLoading from '../PageLoading'
import PageHeader from './PageHeader'
import JobList from '../../job-list/JobList'

// ACTIONS
import { getJobs } from '../../../redux/actions/jobActions'

class JobsPage extends Component {
  loadJobs = () => {
    const { clinicId } = this.props.location.state
    this.props.getJobs(clinicId)
  }

  UNSAFE_componentWillMount () {
    this.loadJobs()
  }

  render () {
    return this.props.loading ? (
      <PageLoading />
    ) : (
      <AppearTransition>
        <div className='page'>
          <PageHeader reload={this.loadJobs} />
          <JobList />
        </div>
      </AppearTransition>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.jobs.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getJobs: clinicId => dispatch(getJobs(clinicId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsPage)
