import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FaSignInAlt } from 'react-icons/fa'

// COMPONENTS
import TextInput from '../../ui-elements/TextInput'
import Button from '../../ui-elements/Button'

// STYLES
import { PageContainer } from '../../../styles/page'
import { palette } from '../../../styles/colors'

// ACTIONS
import { login, checkAuth } from '../../../redux/actions/userActions'

class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            password: '',
            email: ''
        }
    }

    handleLogin = () => {
        const { email, password } = this.state
        if (!password || !email) {
            alert('Bitte geben Sie zuerst E-Mail und Passwort an.')
        } else {
            this.setState({ loading: true }, () =>
                this.props.login(email, password, () =>
                    this.setState({ loading: false })
                )
            )
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ loading: true }, () =>
            this.props.checkAuth(() => this.setState({ loading: false }))
        )
    }

    render() {
        const { loading } = this.state
        const opacity = loading ? 0.75 : 1
        return (
            <PageContainer>
                <Backdrop />
                <LoginModal>
                    <TextInput
                        value={loading ? '' : this.state.email}
                        onChange={event => this.setState({ email: event.target.value })}
                        disabled={loading}
                        loading={loading}
                        type='email'
                        placeholder='E-Mail eingeben...'
                        labelText='E-Mail:'
                        labelColor='white'
                        width='75%'
                        style={{ marginBottom: '19px' }}
                    />
                    <TextInput
                        value={loading ? '' : this.state.password}
                        onChange={event => this.setState({ password: event.target.value })}
                        disabled={loading}
                        loading={loading}
                        type='password'
                        placeholder='Passwort eingeben...'
                        labelText='Passwort:'
                        labelColor='white'
                        width='75%'
                        style={{ marginBottom: '10px' }}
                    />
                    <Button
                        onClick={this.handleLogin}
                        loading={loading}
                        disabled={loading}
                        color='red'
                        iconSize={20}
                        text='Einloggen'
                        style={{ width: '276px', marginTop: '16px', opacity }}
                    >
                        <FaSignInAlt />
                    </Button>
                </LoginModal>
            </PageContainer>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (email, password, toggleLoading) =>
            dispatch(login(email, password, toggleLoading)),
        checkAuth: toggleLoading => dispatch(checkAuth(toggleLoading))
    }
}

export default connect(null, mapDispatchToProps)(LoginPage)

const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  margin-left: -200px;
  margin-top: -150px;
  height: 300px;
  width: 400px;
  border-radius: 6px;
  z-index: 1;
  background: ${palette.primaryDark};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`
const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
`