import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import Job from './list-item/Job'
import FilterPanel from './filter-panel/JobFilterPanel'
import ListHeader from './ListHeader'
import NoElements from '../NoElements'

class JobList extends PureComponent {
  render () {
    return (
      <div className='list-container'>
        <div className='job-list'>
          <ListHeader />
          {renderJobs(this.props.jobs)}
        </div>
        <FilterPanel />
      </div>
    )
  }
}

const renderJobs = function (jobs) {
  const rendered = jobs.reduce(
    function (reducer, job) {
      if (job.show) {
        reducer.index++
        reducer.elements.push(
          <Job key={job._id} index={reducer.index} job={job} showIds />
        )
      }
      return reducer
    },
    { elements: [], index: -1 }
  )
  return rendered.elements.length === 0 ? <NoElements /> : rendered.elements
}

const mapStateToProps = state => {
  return {
    jobs: state.jobs.jobs,
    swapIdModalOpen: state.ui.swapIdModalOpen
  }
}

export default connect(mapStateToProps, null)(JobList)
