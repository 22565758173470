import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// ICONS
import { MdLocationOn } from 'react-icons/md'
import { FaPlus } from 'react-icons/fa'
import { TiDelete } from 'react-icons/ti'
import { IconContext } from 'react-icons'

// COMPONENTS
import SubmitAbortReset from './SubmitAbortReset'
import Checkbox from '../ui-elements/Checkbox'
import TextInput from '../ui-elements/TextInput'
import Editor from '../ui-elements/Editor'
import Button from '../ui-elements/Button'

// STYLES
import { SubHeading, Heading, InputRow } from './style'
import { palette } from '../../styles/colors'

// HELPER
import { validateInt, validateText } from '../../helper/validation'

// ACTIONS
import { closeEditClinic } from '../../redux/actions/uiActions'

class EditClinicForm extends Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         highlightName: false,
         customer: false,
         crawler: false,
         clinicName: '',
         remark: '',
         locations: []
      }
      this.nameInputRef = React.createRef()
      this.locationFormCallbacks = []
   }

   toggleLoading = () => this.setState(prev => ({ loading: !prev.loading }))

   UNSAFE_componentWillMount() {
      this.handleReset()
   }

   handleReset = () => {
      const { clinic } = this.props
      this.setState({
         clinicName: clinic.name,
         remark: clinic.remark,
         crawler: clinic.crawler,
         customer: clinic.customer,
         locations: [...clinic.locations.map(location => ({ ...location }))]
      })
   }

   handleRemarkChange = value => {
      this.setState({ remark: value })
   }

   handleNameChange = ({ target }) => {
      this.setState({
         ...(this.state.highlightName ? { highlightName: false } : {}),
         clinicName: target.value
      })
   }

   handleAddLocation = () => {
      this.setState({
         locations: [
            ...this.state.locations,
            {
               name: '',
               street: '',
               zipCode: '',
               city: '',
               profileId: '',
               cases: '',
               beds: ''
            }
         ]
      })
   }

   handleLocationChange = (e, i, field) => {
		/**
       * e --> onChane event for changed textfield
		 * i --> index of location in locations state
		 * field --> name of field to modify || 'remove' if location should be removed
       */
      this.setState({
         locations:
            field === 'remove'
               ? this.state.locations.filter((_, j) => j !== i)
               : this.state.locations.map((location, j) => {
                  if (i === j) {
                     location[field] = e.target.value
                     return location
                  }
                  return location
               })
      })
   }

   validateLocations = () => {
      let valid = true
      let validated = {}
      const validatedLocations = this.state.locations.map((location, i) => {
         // validate name
         validated = validateText(location.name, true)
         if (!validated.valid) {
            valid = false
            this.locationFormCallbacks[i]('name')
         }
         location.name = validated.value

         // validate profileId
         validated = validateInt(location.profileId, true)
         if (!validated.valid) {
            valid = false
            this.locationFormCallbacks[i]('profileId')
         }
         location.profileId = validated.value

         // validate street
         validated = validateText(location.street, true)
         if (!validated.valid) {
            valid = false
            this.locationFormCallbacks[i]('street')
         }
         location.street = validated.value

         // validate city
         validated = validateText(location.city, true)
         if (!validated.valid) {
            valid = false
            this.locationFormCallbacks[i]('city')
         }
         location.city = validated.value

         // validate zipCode
         validated = validateInt(location.zipCode, true)
         if (!validated.valid) {
            valid = false
            this.locationFormCallbacks[i]('zipCode')
         }
         location.zipCode = validated.value

         // validate beds
         validated = validateInt(location.beds, false)
         if (!validated.valid) {
            valid = false
            this.locationFormCallbacks[i]('beds')
         }
         location.beds = validated.value

         // validate cases
         validated = validateInt(location.cases, false)
         if (!validated.valid) {
            valid = false
            this.locationFormCallbacks[i]('cases')
         }
         location.cases = validated.value
         return location
      })

      return { valid, value: validatedLocations }
   }

   handleSubmit = () => {
      const { clinicName, crawler, customer, remark } = this.state
      const { clinic, closeAndUpdate } = this.props
      let update = {}

		/**
		 * validated of input fields
		 * all invalid fields will be highlighted
		 */
      let invalid = false
      let validated

      // validate clinic name
      validated = validateText(clinicName, true)
      if (!validated.valid) {
         invalid = true
         this.setState({ highlightName: true })
         this.nameInputRef.current.focus()
      }
      update.name = validated.value

      // validate remark
      validated = validateText(remark, false)
      update.remark = validated.value

      // validate locations
      validated = this.validateLocations()
      if (!validated.valid) invalid = true
      update.locations = validated.value

      if (invalid) return

		/**
		 * set binary fields
		 * no validation necessary
		 */
      update.customer = customer
      update.crawler = crawler

      // submit changes
      this.setState({ loading: true }, () =>
         closeAndUpdate({ _id: clinic._id, update }, this.toggleLoading)
      )
   }

   renderLocations = () => {
      this.locationFormCallbacks = []
      return this.state.locations.map((location, i) => (
         <LocationForm
            key={i}
            i={i}
            location={location}
            handleLocationChange={this.handleLocationChange}
            references={this.locationFormCallbacks}
            loading={this.state.loading}
         />
      ))
   }

   render() {
      const { closeNoUpdate } = this.props
      const { loading, highlightName, crawler, customer, clinicName, remark } = this.state
      return (
         <React.Fragment>
            <SubmitAbortReset
               handleSubmit={this.handleSubmit}
               handleAbort={closeNoUpdate}
               handleReset={this.handleReset}
               loading={loading}
               borderType='bottom'
            />
            <Heading>Grundinformation</Heading>
            <InputRow>
               <TextInput
                  value={clinicName}
                  width='50%'
                  reference={this.nameInputRef}
                  highlight={highlightName}
                  loading={loading}
                  disabled={loading}
                  labelText='Klinik Name:'
                  placeholder='Name eingeben ...'
                  onChange={this.handleNameChange}
               />
               <CheckboxContainer>
                  <Checkbox
                     text='Kunde'
                     value={customer}
                     onChange={value => this.setState({ customer: value })}
                     style={{ marginRight: '30px' }}
                  />
                  <Checkbox
                     text='Crawler Aktiv'
                     value={crawler}
                     onChange={value => this.setState({ crawler: value })}
                  />
               </CheckboxContainer>
            </InputRow>
            <Editor
               onChange={this.handleRemarkChange}
               initialValue={remark}
               labelText='Anmerkung'
               width='100%'
               style={{ marginBottom: '30px' }}
            />
            <Heading>Standorte&nbsp;{locationIcon}</Heading>
            {this.renderLocations()}
            <Button
               onClick={this.handleAddLocation}
               color='yellow'
               text='Standort Hinzufügen'
               style={{ width: '210px', marginBottom: '45px' }}
            >
               <FaPlus />
            </Button>
            <SubmitAbortReset
               handleSubmit={this.handleSubmit}
               handleAbort={closeNoUpdate}
               handleReset={this.handleReset}
               loading={loading}
               borderType='top'
            />
         </React.Fragment>
      )
   }
}

const mapStateToProps = state => {
   return {
      clinic: state.ui.clinicToEdit
   }
}

const mapDispatchToProps = dispatch => {
   return {
      closeNoUpdate: () => dispatch(closeEditClinic()),
      closeAndUpdate: (update, toggleLoading) =>
         dispatch(closeEditClinic(update, toggleLoading))
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClinicForm)

const LocationForm = ({ location, handleLocationChange, references, loading, i }) => {
   // state for highlighting invalid inputs
   const [highlightName, setHighlightName] = React.useState(false)
   const [highlightBeds, setHighlightBeds] = React.useState(false)
   const [highlightCases, setHighlightCases] = React.useState(false)
   const [highlightStreet, setHighlightStreet] = React.useState(false)
   const [highlightCity, setHighlightCity] = React.useState(false)
   const [highlightZipCode, setHighlightZipCode] = React.useState(false)
   const [highlightProfileId, setHighlightProfileId] = React.useState(false)
   // refs for highlighting invalid inputs
   const nameInputRef = React.createRef()
   const bedsInputRef = React.createRef()
   const casesInputRef = React.createRef()
   const streetInputRef = React.createRef()
   const cityInputRef = React.createRef()
   const zipCodeInputRef = React.createRef()
   const profileIdInputRef = React.createRef()
   // change handlers
   const remove = () => handleLocationChange(null, i, 'remove')
   const handleNameChange = e => {
      highlightName && setHighlightName(false)
      handleLocationChange(e, i, 'name')
   }
   const handleProfileIdChange = e => {
      highlightProfileId && setHighlightProfileId(false)
      handleLocationChange(e, i, 'profileId')
   }
   const handleCasesChange = e => {
      highlightCases && setHighlightCases(false)
      handleLocationChange(e, i, 'cases')
   }
   const handleBedsChange = e => {
      highlightBeds && setHighlightBeds(false)
      handleLocationChange(e, i, 'beds')
   }
   const handleStreetChange = e => {
      highlightStreet && setHighlightStreet(false)
      handleLocationChange(e, i, 'street')
   }
   const handleCityChange = e => {
      highlightCity && setHighlightCity(false)
      handleLocationChange(e, i, 'city')
   }
   const handleZipCodeChange = e => {
      highlightZipCode && setHighlightZipCode(false)
      handleLocationChange(e, i, 'zipCode')
   }
   // set callback for highlighting
   references[i] = field => {
      switch (field) {
         case 'name':
            nameInputRef.current.focus()
            return setHighlightName(true)
         case 'street':
            streetInputRef.current.focus()
            return setHighlightStreet(true)
         case 'city':
            cityInputRef.current.focus()
            return setHighlightCity(true)
         case 'zipCode':
            zipCodeInputRef.current.focus()
            return setHighlightZipCode(true)
         case 'beds':
            bedsInputRef.current.focus()
            return setHighlightBeds(true)
         case 'cases':
            casesInputRef.current.focus()
            return setHighlightCases(true)
         case 'profileId':
            profileIdInputRef.current.focus()
            return setHighlightProfileId(true)
         default:
            return
      }
   }
   return (
      <Location>
         <SubHeading>
            {location.name}
            <DeleteIcon onClick={remove} />
         </SubHeading>
         <InputRow>
            <TextInput
               value={location.name}
               onChange={handleNameChange}
               reference={nameInputRef}
               highlight={highlightName}
               loading={loading}
               width='49%'
               labelText='Standort'
            />
            <TextInput
               value={location.profileId}
               onChange={handleProfileIdChange}
               reference={profileIdInputRef}
               highlight={highlightProfileId}
               loading={loading}
               width='49%'
               labelText='Profil-ID'
            />
         </InputRow>
         <InputRow>
            <TextInput
               value={location.cases}
               onChange={handleCasesChange}
               reference={casesInputRef}
               highlight={highlightCases}
               loading={loading}
               width='49%'
               labelText='Fallzahl'
            />
            <TextInput
               value={location.beds}
               onChange={handleBedsChange}
               reference={bedsInputRef}
               highlight={highlightBeds}
               loading={loading}
               width='49%'
               labelText='Betten'
            />
         </InputRow>
         <InputRow>
            <TextInput
               value={location.street}
               onChange={handleStreetChange}
               reference={streetInputRef}
               highlight={highlightStreet}
               loading={loading}
               width='45%'
               labelText='Straße/Hausnummer'
            />
            <TextInput
               value={location.city}
               onChange={handleCityChange}
               reference={cityInputRef}
               highlight={highlightCity}
               loading={loading}
               width='35%'
               labelText='Ort'
            />
            <TextInput
               value={location.zipCode}
               onChange={handleZipCodeChange}
               reference={zipCodeInputRef}
               highlight={highlightZipCode}
               loading={loading}
               width='15%'
               labelText='Plz.'
            />
         </InputRow>
      </Location>
   )
}

// STYLED COMPONENTS

const locationIcon = (
   <IconContext.Provider value={{ size: '30px', color: palette.primary }}>
      <MdLocationOn />
   </IconContext.Provider>
)

const DeleteIcon = ({ onClick }) => (
   <div onClick={onClick} className='feedback' style={{ marginLeft: 'auto', width: '40px' }}>
      <IconContext.Provider value={{ size: '35px', color: palette.grayVeryDark }}>
         <TiDelete />
      </IconContext.Provider>
   </div>
)

const Location = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   border: double 1px ${palette.grayDark};
   border-radius: 20px;
   padding: 10px;
   margin-bottom: 40px;
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-self: flex-end;
  width: 50%;
  margin-left: 20px;
`
