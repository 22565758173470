import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import AppearTransition from '../../AppearTransition'
import PageLoading from '../PageLoading'
import ClinicList from '../../clinic-list/ClinicList'
import PageHeader from './PageHeader'

// ACTIONS
import { getClinics, getUserClinics } from '../../../redux/actions/clinicActions'

class ClinicsPage extends Component {
   constructor(props) {
      super(props)
      this.state = { loading: false }
   }

   toggleLoading = () => this.setState(prev => ({ loading: !prev.loading }))

   UNSAFE_componentWillMount() {
      const { getClinics, getUserClinics, userPage } = this.props
      userPage
         ? getUserClinics(this.toggleLoading)
         : getClinics(this.toggleLoading)
   }

   render() {
      return this.state.loading ? (
         <PageLoading />
      ) : (
            <AppearTransition>
               <div className='page' id='clinic-page'>
                  <PageHeader userPage={this.props.userPage} />
                  <ClinicList userPage={this.props.userPage} />
               </div>
            </AppearTransition>
         )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      getClinics: toggleLoading => dispatch(getClinics(toggleLoading)),
      getUserClinics: toggleLoading => dispatch(getUserClinics(toggleLoading))
   }
}

export default connect(null, mapDispatchToProps)(ClinicsPage)
