import React from 'react'
import styled from 'styled-components'

// STYLES
import { BoldFont } from '../../../../styles/fonts'

const Title = ({ title }) => {
  return (
    <TitleFont>
      {title.length > 150 ? title.slice(0, 150) + ' ...' : title}
    </TitleFont>
  )
}

const TitleFont = styled(BoldFont)`
  width: 90%;
  padding: 0 25px 0 25px;
`

export default Title
