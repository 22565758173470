import React from 'react'
import { useSelector } from 'react-redux'

// COMPONENTS
import NumberIndicator from '../../ui-elements/NumberIndicator'

const ClinicsSummary = () => {
  const { nActive, nInactive, nCustomers } = useSelector(
    state => state.clinics.clinicsInformation
  )
  const onlyActive =
    useSelector(state => state.clinics.activeFilter) === 'active'
  return (
    <React.Fragment>
      <NumberIndicator
        margin='0 25px 0 0'
        number={onlyActive ? nActive : nInactive}
        text={onlyActive ? 'Aktiv' : 'Inaktiv'}
      />
      <NumberIndicator margin='0 30px 0 0' number={nCustomers} text='Kunden' />
    </React.Fragment>
  )
}

export default ClinicsSummary
