import React from 'react'
import styled from 'styled-components'

// STYLES
import { palette } from '../../styles/colors'

const NumberIndicator = ({ margin, text, number, children }) => {
  return (
    <Container margin={margin}>
      {text ? <Label>{text}</Label> : children}
      <Number>{number}</Number>
    </Container>
  )
}

export default NumberIndicator

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({ margin }) => margin ?? 'none'};
`

const Number = styled.div`
  height: 26px;
  width: 50px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14pt;
  font-weight: bold;
  background-color: ${palette.grayVeryDark};
`

const Label = styled.label`
  font-size: 14pt;
  margin-right: 10px;
`
