import React from 'react'
import { IconContext } from 'react-icons'
import styled from 'styled-components'

// COMPONENTS
import { ClipLoader } from 'react-spinners'

const Button = ({
   children,
   onClick,
   style,
   small,
   color,
   text,
   iconSize,
   loading,
   disabled
}) => {
   const iconContainer = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: text ? '6px' : 'none'
   }

   return (
      <StyledButton
         onClick={disabled ? () => void 0 : onClick}
         small={small}
         className={color}
         style={style}
      >
         <div style={iconContainer}>
            {loading ? (
               <ClipLoader size={10} color={'white'} loading={true} />
            ) : (
                  <IconContext.Provider value={{ size: iconSize ?? '14px' }}>
                     {children}
                  </IconContext.Provider>
               )}
         </div>
         {text}
      </StyledButton>
   )
}

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 10px;
  height: ${({ small }) => (small ? 24 : 34)}px;
  border-radius: ${({ small }) => (small ? 2.5 : 4)}px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 13pt;
`

export default Button
