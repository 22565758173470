import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

// COMPONENTS
import Job from './Job'
import { ClipLoader } from 'react-spinners'

// ICONS
import { IconContext } from 'react-icons'
import { FaExchangeAlt } from 'react-icons/fa'

// ACTIONS
import { swapId } from '../../../redux/actions/jobActions'

const MatchedJobs = ({ newJob, oldJob }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleSwap = () => {
    dispatch(swapId(newJob, oldJob, () => setLoading(true)))
  }

  return (
    <SwapMatchContainer>
      <Match>
        <Job job={newJob} noActions style={{ borderTopLeftRadius: '10px' }} />
        <Job job={oldJob} noActions style={{ borderBottomLeftRadius: '10px' }} />
      </Match>
      <Swap className='green' onClick={handleSwap}>
        {loading ? (
          <ClipLoader size={23} color='white' loading={true} />
        ) : (
          <IconContext.Provider value={{ size: '23px', color: 'white' }}>
            <FaExchangeAlt />
          </IconContext.Provider>
        )}
      </Swap>
    </SwapMatchContainer>
  )
}

const SwapMatchContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 15px 0 15px 0;
`

const Match = styled.div`
  display: flex;
  flex-direction: column;
  width: 92.5%;
`

const Swap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  width: 7.5%;
`

export default MatchedJobs
