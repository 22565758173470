import React from 'react'
import styled from 'styled-components'

// COMPONENTS
import Id from './Id'

// STYLES
import { BoldFont } from '../../../../styles/fonts'
import { palette } from '../../../../styles/colors'

const ShowIds = ({ job, job: { valmediId } }) => {
  const heading =
    valmediId.length === 0 ? 'ID: -' : valmediId.length === 1 ? 'ID:' : "ID's:"
  return (
    <DisplayIdContainer>
      <BoldFont style={{ marginLeft: '25px' }}>
        {heading}
        &nbsp;&nbsp;&nbsp;&nbsp;
      </BoldFont>
      <Ids>
        {valmediId.map(id => (
          <Id key={id} id={id} job={job} />
        ))}
      </Ids>
    </DisplayIdContainer>
  )
}

const Ids = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const DisplayIdContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 57.5%;
  border-bottom: solid 0.5px ${palette.gray};
`

export default ShowIds
