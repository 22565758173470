import React from 'react'
import { BeatLoader } from 'react-spinners'
import { css } from '@emotion/core'

// STYLES
import { palette } from '../../styles/colors'
import { PageContainer } from '../../styles/page'

const PageLoading = () => {
  const overrideCSS = css`
    margin-top: 20%;
    opacity: 0.75;
  `
  return (
    <PageContainer>
      <BeatLoader css={overrideCSS} size={20} color={palette.primary} />
    </PageContainer>
  )
}

export default PageLoading
