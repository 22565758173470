import React from 'react'
import { useDispatch } from 'react-redux'

// COMPONENTS
import Header from '../../FilterPanelHeader'
import SearchId from './filter/SearchId'
import SearchTitle from './filter/SearchTitle'
import SortBy from './filter/SortBy'
import JobFilter from './filter/JobFilter'
import Button from '../../ui-elements/Button'

// ICONS
import { FaExchangeAlt } from 'react-icons/fa'

// ACTIONS
import { TOGGLE_MODAL } from '../../../redux/actions/uiActions'

const FilterPanel = () => {
  const dispatch = useDispatch()
  const openModal = () => dispatch({ type: TOGGLE_MODAL, modal: 'swapId' })

  return (
    <div className='filter-panel' style={{ height: '490px' }}>
      <Header text='Stellen Filtern' />
      <JobFilter />
      <SearchId />
      <SearchTitle />
      <Button
        onClick={openModal}
        text='Titel vergleichen'
        color='blue'
        style={{ width: '83%' }}
        iconSize={16}
      >
        <FaExchangeAlt />
      </Button>
      <SortBy />
    </div>
  )
}

export default FilterPanel
