import axios from 'axios'

// HELPER
import { handleHttpError, sortListBy } from '../../helper/helper'

export const SET_JOB_STATE = 'SET_JOB_STATE'
export const APPLY_FILTERS = 'APPLY_FILTERS'
export const UPDATE_JOB = 'UPDATE_JOB'
export const REMOVE_JOB = 'REMOVE_JOB'
export const UPDATE_CURRENT_CLINIC = 'UPDATE_CURRENT_CLINIC'
export const TOGGLE_JOBS_PAGE_LOADING = 'TOGGLE_JOBS_PAGE_LOADING'
export const RESET_FILTERS = 'RESET_FILTERS'

export const getJobs = clinicId => {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_JOBS_PAGE_LOADING })
    try {
      const currentClinic = await axios.get(
        process.env.REACT_APP_API_ROUTE + `/clinics/get-clinic/${clinicId}`,
        { headers: { Authorization: 'Bearer ' + getState().users.token } }
      )
      const jobs = await axios.get(
        process.env.REACT_APP_API_ROUTE + `/jobs/get-jobs/${clinicId}`,
        { headers: { Authorization: 'Bearer ' + getState().users.token } }
      )
      sortListBy(jobs.data, 'valmediId', true)
      dispatch({
        type: SET_JOB_STATE,
        payload: { currentClinic: currentClinic.data, jobs: jobs.data }
      })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    dispatch({ type: RESET_FILTERS })
    dispatch({ type: TOGGLE_JOBS_PAGE_LOADING })
  }
}

export const updateJob = (update, toggleLoading = () => void 0) => {
  return async (dispatch, getState) => {
    toggleLoading()
    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_ROUTE + '/jobs/update-job',
        update,
        { headers: { Authorization: 'Bearer ' + getState().users.token } }
      )
      const job = response.data
      job.show = true
      dispatch({ type: UPDATE_JOB, job })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
  }
}

export const removeJob = (_id, clinicId, toggleLoading, noLog) => {
  return async (dispatch, getState) => {
    toggleLoading()
    try {
      await axios.delete(process.env.REACT_APP_API_ROUTE + '/jobs/remove-job', {
        data: { _id, clinicId, noLog },
        headers: { Authorization: 'Bearer ' + getState().users.token }
      })
      dispatch({ type: REMOVE_JOB, _id })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
  }
}

export const swapId = (newJob, oldJob, toggleLoading) => {
  const { _id: updateId, clinicId } = newJob
  const { _id: removeId, valmediId } = oldJob
  return async dispatch => {
    toggleLoading()
    try {
      dispatch(removeJob(removeId, clinicId, () => void 0, true))
      dispatch(
        updateJob(
          {
            _id: updateId,
            clinicId,
            update: { valmediId, status: 0 },
            noLog: true
          },
          () => void 0
        )
      )
      dispatch({
        type: UPDATE_CURRENT_CLINIC,
        updates: { new_: -1, old: -1, online: 1 }
      })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
  }
}

export const setFilter = payload => {
  return dispatch => {
    dispatch({ type: SET_JOB_STATE, payload })
    dispatch({ type: APPLY_FILTERS })
  }
}
