import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

// COMPONENTS
import Modal from './Modal'
import EditIdForm from '../forms/EditIdForm'

// ACTIONS
import { closeEditId } from '../../redux/actions/uiActions'

const EditClinicModal = () => {
   const dispatch = useDispatch()
   const handleClose = () => dispatch(closeEditId())
   const modalOpen = useSelector(state => state.ui.editIdModalOpen)
   return (
      <Modal show={modalOpen} onClose={handleClose}>
         <ModalContainer width={450} height={290}>
            <EditIdForm />
         </ModalContainer>
      </Modal>
   )
}

const ModalContainer = styled.div`
  z-index: 100;
  overflow-y: scroll;
  padding: 0 21px 0 21px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  margin-left: -${({ width }) => width / 2 + 'px'};
  margin-top: -${({ height }) => height / 2 + 'px'};
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`

export default EditClinicModal
