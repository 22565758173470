import React from 'react'
import styled from 'styled-components'

// STYLES
import { palette } from '../../styles/colors'
import { BoldFont } from '../../styles/fonts'

const TextInput = props => {
   const {
      reference,
      labelText,
      loading,
      disabled,
      width,
      labelColor,
      value
   } = props
   const input = (
      <Input
         {...props}
         value={value === null || typeof value === 'undefined' ? '' : value}
         spellCheck={false}
         ref={reference}
      />
   )
   return labelText ? (
      <LabelContainer width={width}>
         <Label loading={loading} disabled={disabled} labelColor={labelColor}>
            {labelText}
         </Label>
         {input}
      </LabelContainer>
   ) : (
         input
      )
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '100px')};
`
const Label = styled(BoldFont)`
  margin-bottom: 4px;
  color: ${({ labelColor }) => labelColor ?? palette.blackText};
  opacity: ${({ loading, disabled }) => (loading || disabled ? 0.4 : 1)};
`

const Input = styled.input`
  height: ${({ height }) => height ?? '39px'};
  width: ${({ width, labelText }) => (labelText ? '100%' : width ?? '100px')};
  ${({ highlight }) =>
      highlight
         ? `
      border: solid 2px ${palette.alarmRed};
      color: ${palette.alarmRed};
      outline-color: ${palette.alarmRed};
      font-weight: bold;
      `
         : `
      border: solid 1px ${palette.grayDark};
      color: ${palette.blackText};
      outline-color: ${palette.primaryLight};
      font-weight: normal;
    `}
  font-size: 12pt;
  opacity: ${({ loading, disabled }) => (loading || disabled ? 0.4 : 1)};
  padding: 0 7px 0 7px;
  box-sizing: border-box;
  border-radius: 4px;
`

export default TextInput
