import axios from 'axios'

// HELPER
import { handleHttpError } from '../../helper/helper'

export const SET_USER_OPTIONS = 'SET_USER_OPTIONS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const TOGGLE_LOGIN_LOADING = 'TOGGLE_LOGIN_LOADING'

export const createUser = (newUser, toggleLoading) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ROUTE + '/users/create-user',
        newUser,
        { headers: { Authorization: 'Bearer ' + getState().users.token } }
      )
      dispatch({
        type: SET_USER_OPTIONS,
        userOptions: [...getState().users.userOptions, response.data]
      })
    } catch (error) {
      handleHttpError(error, dispatch)
    }
    toggleLoading()
  }
}

export const getUserOptions = () => {
  return async (dispatch, getState) => {
    const response = await axios.get(
      process.env.REACT_APP_API_ROUTE + '/users/get-user-options',
      { headers: { Authorization: 'Bearer ' + getState().users.token } }
    )
    dispatch({ type: SET_USER_OPTIONS, userOptions: response.data })
  }
}

export const login = (email, password, toggleLoading) => {
  return async dispatch => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ROUTE + '/users/login',
        { email, password }
      )
      await localStorage.setItem('valmedi-token', response.data.token)
      setTimeout(() => {
        dispatch({ type: SET_USER_DATA, isLoggedIn: true, ...response.data })
      }, 500)
    } catch (error) {
      handleHttpError(error, dispatch)
      toggleLoading()
    }
  }
}

export const checkAuth = toggleLoading => {
  return async dispatch => {
    const token = await localStorage.getItem('valmedi-token')
    if (token) {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_ROUTE + '/users/verify-token',
          { headers: { Authorization: 'Bearer ' + token } }
        )
        setTimeout(() => {
          dispatch({
            type: SET_USER_DATA,
            isLoggedIn: true,
            token,
            ...response.data
          })
        }, 500)
      } catch (error) {
        toggleLoading()
      }
    } else {
      toggleLoading()
    }
  }
}

export const logout = () => {
  return async dispatch => {
    await localStorage.setItem('valmedi-token', null)
    dispatch({
      type: SET_USER_DATA,
      isLoggedIn: false,
      token: null,
      admin: null,
      userId: null,
      userName: null,
      userClinics: []
    })
  }
}
