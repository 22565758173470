import React from 'react'

// COMPONENTS
import JobInfo from '../../../ui-elements/JobInfo'

const JobStatus = ({ status }) => {
  const color =
    status === 0
      ? 'green'
      : status === 1
      ? 'yellow'
      : status === -1
      ? 'red'
      : 'gray'
  return (
    <JobInfo
      style={{ margin: '0 0 0 auto', justifyContent: 'center', width: '10%' }}
      color={color}
      size={20}
    />
  )
}

export default JobStatus
