import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

// COMPONENTS
import Navbar from './components/navbar/Navbar'
import ClinicsPage from './components/pages/clinics-page/ClinicsPage'
import JobsPage from './components/pages/jobs-page/JobsPage'
import MapPage from './components/pages/map-page/MapPage'
import LoginPage from './components/pages/login-page/LoginPage'
import ScrollToTop from './components/ScrollToTop'

// MODALS
import ErrorModal from './components/modals/ErrorModal'
import ConfirmModal from './components/modals/ConfirmModal'
import EditClinicModal from './components/modals/EditClinicModal'
import CreateUserModal from './components/modals/CreateUserModal'
import SwapIdModal from './components/modals/SwapIdModal'
import EditIdModal from './components/modals/EditIdModal'

const App = () => {
   const isLoggedIn = useSelector(state => state.users.isLoggedIn)
   return (
      <React.Fragment>
         <EditIdModal />
         <SwapIdModal />
         <CreateUserModal />
         <EditClinicModal />
         <ErrorModal />
         <ConfirmModal />
         <ScrollToTop />
         <BrowserRouter>
            <Navbar />
            {isLoggedIn ? (
               <Switch>
                  <Route
                     path='/clinics'
                     component={() => <ClinicsPage userPage={false} />}
                     exact
                  />
                  <Route
                     path='/user'
                     component={() => <ClinicsPage userPage={true} />}
                     exact
                  />
                  <Route path='/jobs' component={JobsPage} exact />
                  <Route
                     path='/map'
                     component={() => <MapPage />}
                     exact
                  />
                  <Redirect to='/user' />
               </Switch>
            ) : (
                  <LoginPage />
               )}
         </BrowserRouter>
      </React.Fragment>
   )
}

export default App