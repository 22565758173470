import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

// ICONS
import { IconContext } from 'react-icons'
import { TiDelete } from 'react-icons/ti'
import { ClipLoader } from 'react-spinners'

// STYLES
import { BoldFont } from '../../../../styles/fonts'

// ACTIONS
import { postConfirm, openEditId } from '../../../../redux/actions/uiActions'
import { updateJob } from '../../../../redux/actions/jobActions'

const Id = ({ job, job: { valmediId, clinicId, _id, status }, id }) => {
  const dispatch = useDispatch()
  const [hover, setHover] = useState(false)
  const [loading, setLoading] = useState(false)
  const disabled = status === -1 || status === -2

  const toggleLoading = () => setLoading(prev => !prev)

  const removeId = () => {
    const executeDelete = () => {
      const newIds = valmediId.filter(currentId => currentId !== id)
      const update = { valmediId: newIds }
      dispatch(updateJob({ _id, clinicId, update }, toggleLoading))
    }
    if (valmediId.length === 1) dispatch(openEditId(job, toggleLoading))
    else {
      dispatch(
        postConfirm(
          {
            text: `Möchtest die verknüpfte Valmedi-ID ${id} entfernen?`,
            actionText: 'ID Entfernen'
          },
          executeDelete
        )
      )
    }
  }

  return (
    <IdContainer
      onMouseEnter={() => (disabled ? void 0 : setHover(true))}
      onMouseLeave={() => (disabled ? void 0 : setHover(false))}
      onClick={disabled ? () => void 0 : removeId}
      hover={hover}
    >
      {id}
      {loading ? (
        <ClipLoader size={11} color='black' loading={true} />
      ) : (
        <IconContext.Provider
          value={{
            size: '21.5px',
            color: hover ? 'black' : 'rgb(110, 110, 110)'
          }}
        >
          <TiDelete />
        </IconContext.Provider>
      )}
    </IdContainer>
  )
}

const IdContainer = styled(BoldFont)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 21px;
  padding: 0 7px 0 7px;
  margin: 1.5px 10px 1.5px 0;
  border-radius: 10.5px;
  border: solid 0.5px rgb(190, 190, 190);
  background: white;
  cursor: pointer;
  transform: scale(${({ hover }) => (hover ? '1.1' : '1')});
`

export default Id
