import React from 'react'
import styled from 'styled-components'

// STYLES
import { BoldFont } from '../../styles/fonts'
import { palette, backgroundColors } from '../../styles/colors'

const ListHeader = () => {
  return (
    <HeaderContainer>
      <HeaderCell width='65%'>Stellenanzeige</HeaderCell>
      <HeaderCell width='35%'>Valmedi-ID</HeaderCell>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid 1px ${palette.grayDark};
  background: ${backgroundColors.gray};
`

const HeaderCell = styled(BoldFont)`
  width: ${({ width }) => width};
  padding: 7px 0 8px 25px;
`

export default ListHeader
