import React from 'react'

// COMPONENTS
import ScrollToTopWrapper from 'react-scroll-up'

// ICONS
import { IconContext } from 'react-icons'
import { IoIosArrowDropupCircle } from 'react-icons/io'

// STYLES
import { palette } from '../styles/colors'

const ScrollToTop = () => {
   return (
      <ScrollToTopWrapper showUnder={50}>
         <IconContext.Provider value={{ size: '90px', color: palette.primary }}>
            <IoIosArrowDropupCircle />
         </IconContext.Provider>
      </ScrollToTopWrapper>
   )
}

export default ScrollToTop
