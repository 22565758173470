import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import Button from '../../../ui-elements/Button'

// ICONS
import { FaTrashAlt, FaArchive } from 'react-icons/fa'

// ACTIONS
import { postConfirm } from '../../../../redux/actions/uiActions'
import {
  updateJob,
  removeJob,
  UPDATE_CURRENT_CLINIC
} from '../../../../redux/actions/jobActions'

class ActionButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  toggleLoading = () => {
    this.setState(prevState => ({ loading: !prevState.loading }))
  }

  handleDelete = () => {
    const {
      removeJob,
      postConfirm,
      updateCurrentClinic,
      job: { clinicId, _id, valmediId }
    } = this.props
    const manyIds = valmediId.length > 1
    const executeDelete = () => {
      removeJob(_id, clinicId, this.toggleLoading)
      updateCurrentClinic({ old: -1 })
    }
    postConfirm(
      {
        text: `Möchtest die Anzeige mit ${
          manyIds ? "den Valmedi-ID's" : 'der Valmedi-ID'
        } ${valmediId.reduce(
          (result, id, i) =>
            result + id + (i === valmediId.length - 1 ? '' : ', '),
          ''
        )} wirklich löschen?`,
        actionText: 'Löschen'
      },
      executeDelete
    )
  }

  handleIgnore = () => {
    const {
      updateJob,
      updateCurrentClinic,
      job: { clinicId, _id }
    } = this.props
    updateJob(
      {
        _id,
        clinicId,
        update: {
          status: -2
        }
      },
      this.toggleLoading
    )
    updateCurrentClinic({ new_: -1 })
  }

  handleInclude = () => {
    const {
      updateJob,
      updateCurrentClinic,
      job: { clinicId, _id }
    } = this.props
    updateJob(
      {
        _id,
        clinicId,
        update: {
          status: 1
        }
      },
      this.toggleLoading
    )
    updateCurrentClinic({ new_: 1 })
  }

  render () {
    const {
      job: { status }
    } = this.props
    let info
    if (status === 1)
      info = {
        color: 'yellow',
        text: 'Ignorieren',
        icon: <FaArchive />,
        callback: this.handleIgnore
      }
    else if (status === -1)
      info = {
        color: 'red',
        text: 'Löschen',
        icon: <FaTrashAlt />,
        callback: this.handleDelete
      }
    else
      info = {
        color: 'green',
        text: 'Einbeziehen',
        icon: <FaArchive />,
        callback: this.handleInclude
      }
    return (
      status !== 0 && (
        <Button
          small={true}
          loading={this.state.loading}
          color={info.color}
          text={info.text}
          onClick={info.callback}
          style={{ width: '120px' }}
        >
          {info.icon}
        </Button>
      )
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateJob: (update, toggleLoading) =>
      dispatch(updateJob(update, toggleLoading)),
    removeJob: (_id, clinicId, toggleLoading) =>
      dispatch(removeJob(_id, clinicId, toggleLoading)),
    updateCurrentClinic: updates =>
      dispatch({ type: UPDATE_CURRENT_CLINIC, updates }),
    postConfirm: (confirmMessage, confirmAction) =>
      dispatch(postConfirm(confirmMessage, confirmAction))
  }
}

export default connect(null, mapDispatchToProps)(ActionButton)
