import React from 'react'
import { withRouter } from 'react-router-dom'

// ICONS
import { FaList } from 'react-icons/fa'

// COMPONENTS
import Button from '../../../ui-elements/Button'

const GoToJobs = ({ clinicId, history, style }) => {
  const routeJobs = () => {
    history.push({
      pathname: '/jobs',
      state: { clinicId }
    })
  }
  return (
    <Button
      small={true}
      color='blue'
      text='Stellen'
      style={{ width: '90px', ...style }}
      onClick={routeJobs}
    >
      <FaList />
    </Button>
  )
}

export default withRouter(GoToJobs)
