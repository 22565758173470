import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

// COMPONENTS
import Header from '../../FilterPanelHeader'
import SearchClinic from './filter/SearchClinic'
import FilterCustomers from './filter/FilterCustomers'
import SortBy from './filter/SortBy'
import FilterActive from './filter/FilterActive'
import FilterUsers from './filter/FilterUsers'
import FilterStates from './filter/FilterStates'

// ACTIONS
import { SET_CLINIC_STATE } from '../../../redux/actions/clinicActions'

const ClinicFilterPanel = ({ history }) => {
    const dispatch = useDispatch()
    const userPage = useSelector(state => state.clinics.userPage)

    const currentPath = history.location.pathname
    useEffect(() => {
        dispatch({
            type: SET_CLINIC_STATE,
            payload: { userPage: currentPath === '/user' ? true : false }
        })
    }, [currentPath, dispatch])

    return (
        <div
            className='filter-panel'
            style={{ height: userPage ? '345px' : '540px' }}
        >
            <Header text='Kliniken Filtern' />
            <SearchClinic />
            {!userPage && <FilterStates />}
            {!userPage && <FilterUsers />}
            <FilterCustomers />
            {!userPage && <FilterActive />}
            <SortBy />
        </div>
    )
}

export default withRouter(ClinicFilterPanel)
