import { updateClinic } from './clinicActions'
import { updateJob } from './jobActions'
import { createUser } from './userActions'

export const SET_UI_STATE = 'SET_UI_STATE'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'

export const postError = (errorMessage, errorAction) => {
  return dispatch => {
    if (errorAction) dispatch({ type: SET_UI_STATE, errorAction })
    dispatch({ type: SET_UI_STATE, errorMessage })
    dispatch({ type: TOGGLE_MODAL, modal: 'error' })
  }
}

export const closeError = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_MODAL, modal: 'error' })
    dispatch({
      type: SET_UI_STATE,
      errorMessage: '',
      errorAction: () => void 0
    })
  }
}

export const postConfirm = (confirmMessage, confirmAction) => {
  return dispatch => {
    dispatch({ type: SET_UI_STATE, confirmMessage, confirmAction })
    dispatch({ type: TOGGLE_MODAL, modal: 'confirm' })
  }
}

export const closeConfirm = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_MODAL, modal: 'confirm' })
    dispatch({
      type: SET_UI_STATE,
      confirmMessage: '',
      confirmAction: () => void 0
    })
  }
}

export const openEditClinic = clinic => {
  return dispatch => {
    dispatch({ type: SET_UI_STATE, clinicToEdit: clinic })
    dispatch({ type: TOGGLE_MODAL, modal: 'editClinic' })
  }
}

export const closeEditClinic = (
  update = null,
  toggleLoading = () => void 0
) => {
  return async dispatch => {
    const resetModal = () => {
      dispatch({ type: TOGGLE_MODAL, modal: 'editClinic' })
      dispatch({ type: SET_UI_STATE, clinicToEdit: {} })
    }
    if (update) {
      dispatch(
        updateClinic(update, () => {
          toggleLoading()
          resetModal()
        })
      )
    } else resetModal()
  }
}

export const toggleCreateUserModal = (
  newUser = null,
  toggleLoading = () => void 0
) => {
  return dispatch => {
    if (newUser) {
      dispatch(
        createUser(newUser, () => {
          toggleLoading()
          dispatch({ type: TOGGLE_MODAL, modal: 'createUser' })
        })
      )
    } else dispatch({ type: TOGGLE_MODAL, modal: 'createUser' })
  }
}

export const openEditId = (jobToEdit, toggleLoading = () => void 0) => {
  return dispatch => {
    dispatch({ type: TOGGLE_MODAL, modal: 'editId' })
    dispatch({ type: SET_UI_STATE, jobToEdit, toggleLoading })
  }
}

export const closeEditId = (update = null) => {
  return (dispatch, getState) => {
    if (update) {
      dispatch(updateJob(update, getState().ui.toggleLoading))
    }
    dispatch({ type: TOGGLE_MODAL, modal: 'editId' })
    dispatch({ type: SET_UI_STATE, jobToEdit: {} })
  }
}
