import React from 'react'
import { useDispatch } from 'react-redux'

// ICONS
import { FaUserPlus } from 'react-icons/fa'

// COMPONENTS
import Button from '../components//ui-elements/Button'

// ACTIONS
import { toggleCreateUserModal } from '../redux/actions/uiActions'

const CreateUserButton = () => {
   const dispatch = useDispatch()
   const openModal = () => {
      dispatch(toggleCreateUserModal())
   }

   return (
      <Button
         onClick={openModal}
         text='Nutzer anlegen'
         color='green'
         iconSize={16}
         style={{ width: '165px' }}
      >
         <FaUserPlus />
      </Button>
   )
}

export default CreateUserButton
