import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const AppearTransition = props => {
  return (
    <ReactCSSTransitionGroup
      transitionName='appearTransition'
      transitionAppear={true}
      transitionAppearTimeout={400}
      transitionLeaveTimeout={400}
      transitionEnterTimeout={400}
    >
      {props.children}
    </ReactCSSTransitionGroup>
  )
}

export default AppearTransition
