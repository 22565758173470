import React from 'react'
import { useSelector } from 'react-redux'

// COMPONENTS
import ReactTooltip from 'react-tooltip'
import JobInfo from '../../../ui-elements/JobInfo'

// STYLES
import { palette } from '../../../../styles/colors'
import { boldFont } from '../../../../styles/fonts'

export default function JobOverview () {
  const clinics = useSelector(state => state.clinics.clinics)
  let online = 0
  let new_ = 0
  let old = 0
  for (let clinic of clinics) {
    if (clinic.show) {
      online += clinic.online
      new_ += clinic.new_
      old += clinic.old
    }
  }
  return (
    <ReactTooltip
      id='job-overview'
      type='info'
      effect='solid'
      backgroundColor={palette.garyLight}
      className='tooltip'
    >
      <div
        style={{
          ...boldFont,
          borderBottom: 'solid 0.8px',
          marginBottom: '10px'
        }}
      >
        Zusammenfassung
      </div>
      <JobInfo
        text='online'
        color='green'
        number={online}
        style={{ marginBottom: '5px' }}
      />
      <JobInfo
        text='neu'
        color='yellow'
        number={new_}
        style={{ marginBottom: '5px' }}
      />
      <JobInfo
        text='alt'
        color='red'
        number={old}
        style={{ marginBottom: '5px' }}
      />
    </ReactTooltip>
  )
}
