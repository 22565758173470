import React from 'react'
import styled from 'styled-components'

// COMPONENTS
import JobOverview from './list-item/item-components/JobOverview'

// ICONS
import { IconContext } from 'react-icons'
import { FaInfoCircle } from 'react-icons/fa'

// STYLES
import { BoldFont } from '../../styles/fonts'
import { palette, backgroundColors } from '../../styles/colors'

const ListHeader = () => {
  return (
    <HeaderContainer>
      <HeaderCell padding='8px 0 8px 25px;' width='64%'>
        Klinik&nbsp;&nbsp;
      </HeaderCell>
      <HeaderCell
        style={{ cursor: 'pointer' }}
        padding='8px 0 8px 40px;'
        width='36%'
        data-tip
        data-for='job-overview'
      >
        Stellenanzeigen&nbsp;&nbsp;
        <IconContext.Provider value={{ size: '18px', color: palette.primary }}>
          <FaInfoCircle />
        </IconContext.Provider>
        <JobOverview />
      </HeaderCell>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid 1px ${palette.grayDark};
  background: ${backgroundColors.gray};
`

const HeaderCell = styled(BoldFont)`
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
`

export default ListHeader
