// HELPER
import { sortListBy, filterClinics } from '../../helper/helper'

import {
  APPLY_FILTERS,
  UPDATE_CLINIC,
  RESET_FILTERS,
  SET_CLINIC_STATE,
  UPDATE_CLINICS_INFO
} from '../actions/clinicActions'

const defaultFilters = {
  searchKey: '', // 'key' || ''
  activeFilter: 'active', // 'active' || 'inactive'
  userFilter: 'all', // 'all' || {user}
  customerFilter: 'all', // 'all' || 'customer' || 'no-customer'
  sortByFilter: {
    sortBy: null, // 'none' || 'something'
    descending: true
  },
  stateFilter: 'all' // 'all' || 'BY', 'HE', 'NRW' ...
}

const initialState = {
  userPage: true,
  clinics: [],
  clinicOptions: [],
  clinicsInformation: {
    nActive: 0,
    nInactive: 0,
    nCustomers: 0
  },
  ...defaultFilters
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLINIC_STATE:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_CLINIC:
      return {
        ...state,
        clinics: state.clinics.map(clinic => {
          if (clinic._id === action.clinic._id) {
            return action.clinic
          }
          return clinic
        })
      }
    case UPDATE_CLINICS_INFO:
      const clinicsInformation = { ...state.clinicsInformation }
      for (let key in action.updates) {
        clinicsInformation[key] += action.updates[key]
      }
      return {
        ...state,
        clinicsInformation
      }
    case APPLY_FILTERS:
      const clinics = filterClinics(state)
      const { sortBy, descending } = state.sortByFilter
      sortBy && sortListBy(clinics, sortBy, descending)
      return {
        ...state,
        clinics
      }
    case RESET_FILTERS:
      return {
        ...state,
        ...defaultFilters
      }
    default:
      return state
  }
}

export default reducer
