import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import Dropdown from '../../../ui-elements/Dropdown'

// ACTIONS
import { updateClinic } from '../../../../redux/actions/clinicActions'

class AsignUser extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  setClinicUser = user => {
    const _id = this.props.clinic._id
    this.setState({ loading: true }, () => {
      this.props.updateClinic({ _id, update: { user } }, () =>
        this.setState({ loading: false })
      )
    })
  }

  render () {
    const {
      clinic: { user, active },
      userOptions,
      admin,
      style
    } = this.props

    return (
      <Dropdown
        options={userOptions}
        value={user}
        onChange={this.setClinicUser}
        valueField='userId'
        labelField='name'
        isDisabled={!admin || this.state.loading || !active}
        isLoading={this.state.loading}
        isSearchable={false}
        isClearable
        placeholder='Sachbearbeiter'
        width='170px'
        zIndex={100}
        containerStyle={style}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    userOptions: state.users.userOptions,
    admin: state.users.admin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateClinic: (update, toggleLoading) =>
      dispatch(updateClinic(update, toggleLoading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsignUser)
