import React, { useState } from 'react'

// ICONS
import { FaCrosshairs, FaCheck } from 'react-icons/fa'
import { IconContext } from 'react-icons'

// STYLES
import { palette } from '../../../../styles/colors'

const Focus = ({ focused, toggleFocus }) => {
  const [hover, setHover] = useState(false)
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        marginLeft: '26px',
        transform: hover ? 'scale(1.15)' : 'scale(1)',
        cursor: 'pointer'
      }}
      onClick={toggleFocus}
    >
      <IconContext.Provider
        value={{ size: '22px', color: palette.primaryDark }}
      >
        {focused ? <FaCheck /> : <FaCrosshairs />}
      </IconContext.Provider>
    </div>
  )
}

export default Focus
