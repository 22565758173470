import React from 'react'
import styled from 'styled-components'

// COMPONENTS
import StatusIndicator from './StatusIndicator'

// STYLE
import { normalFont, boldFont } from '../../styles/fonts'

const JobInfo = ({ bold, color, text, number, style, size }) => {
  return (
    <Container style={style}>
      {text && (
        <div
          style={{
            width: isNaN(number) || '60px',
            marginRight: !isNaN(number) || '10px',
            ...(bold ? boldFont : normalFont)
          }}
        >
          {text}
        </div>
      )}
      <StatusIndicator color={color} size={size} />
      {!isNaN(number) && (
        <div
          style={{
            marginLeft: '10px',
            ...boldFont
          }}
        >
          {number}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

export default JobInfo
