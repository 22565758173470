import React from 'react'

// STYLES
import { palette } from '../../styles/colors'

const StatusIndicator = ({ color, size, style }) => {
  let background
  let borderColor
  if (color === 'green') {
    background = `linear-gradient(to bottom, ${palette.greenLight} , ${palette.green} 33%, ${palette.greenDark} 66%)`
    borderColor = palette.greenDark
  } else if (color === 'red') {
    background = `linear-gradient(to bottom, ${palette.redLight} , ${palette.red} 33%, ${palette.redDark} 66%)`
    borderColor = palette.redDark
  } else if (color === 'yellow') {
    background = `linear-gradient(to bottom, ${palette.yellowLight} , ${palette.yellow} 33%, ${palette.yellowDark} 66%)`
    borderColor = palette.yellowDark
  } else if (color === 'gray') {
    background = `linear-gradient(to bottom, ${palette.garyLight} ,${palette.grayDark} 30%)`
    borderColor = palette.grayDark
  } else {
    background = `linear-gradient(to bottom, ${palette.primaryLight} , ${palette.primary} 33%, ${palette.primaryDark} 66%)`
    borderColor = palette.primaryDark
  }
  const containerStyle = {
    height: size ? `${size}px` : '18px',
    width: size ? `${size}px` : '18px',
    borderRadius: size ? `${size / 2 + 0.5}px` : '9.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background,
    border: `solid 1px ${borderColor}`
  }

  return <div style={{ ...containerStyle, ...style }} />
}

export default StatusIndicator
