import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IoIosAlert } from 'react-icons/io'
import { IconContext } from 'react-icons'

// COMPONENTS
import Modal from './Modal'
import Button from '../ui-elements/Button'

// STYLES
import { palette } from '../../styles/colors'
import {
    ModalContainer,
    HeaderContainer,
    MessageContainer,
    ButtonContainer
} from './style'

// ACTIONS
import { closeError } from '../../redux/actions/uiActions'

const ErrorModal = () => {
    const modalOpen = useSelector(state => state.ui.errorModalOpen)
    const errorAction = useSelector(state => state.ui.errorAction)
    const message = useSelector(state => state.ui.errorMessage)

    const dispatch = useDispatch()
    const closeModal = () => {
        errorAction()
        dispatch(closeError())
    }

    return (
        <Modal show={modalOpen} onClose={closeModal}>
            <ModalContainer
                width={450}
                height={300}
            >
                <HeaderContainer>
                    <IconContext.Provider value={{ size: '60px', color: palette.red }}>
                        <IoIosAlert />
                    </IconContext.Provider>
                    <h2>Es ist ein Fehler aufgetreten</h2>
                </HeaderContainer>
                <MessageContainer>{message}</MessageContainer>
                <ButtonContainer>
                    <Button
                        onClick={closeModal}
                        color='blue'
                        text='Schließen'
                        style={{
                            borderRadius: '4px 4px 5px 5px',
                            height: '42.5px',
                            width: '100%'
                        }}
                    />
                </ButtonContainer>
            </ModalContainer>
        </Modal>
    )
}

export default ErrorModal
