import React, { Component } from 'react'
import leaflet from 'leaflet'

// COMPONENTS
import ClinicPanel from './ClinicPanel'

// ASSETS
import { stateFeatures } from '../../assets/stateFeatures'

// STYLES
import { palette } from '../../styles/colors'

class Map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
        this.stateFeatureMap = {}
        this.markerLayer = null
        this.map = null
    }

    componentDidMount() {
        this.map = leaflet.map('map', mapOptions)
        leaflet.tileLayer(
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }
        ).addTo(this.map)
        /**
         * adding the state layers
         * note: onClick/Hover must be set in Panels
         *       due to specific state
         */
        const stateLayer = leaflet.geoJSON(stateFeatures, { style: stateLayerStyles.normalStyle })
        stateLayer.addTo(this.map)
        for (let key in stateLayer._layers) {
            const state = stateLayer._layers[key]
            this.stateFeatureMap[state.feature.id] = state
        }
        this.setState({ loading: false })
    }

    addMarkersToMap = markers => {
        if (this.markerLayer) this.markerLayer.clearLayers()
        this.markerLayer = leaflet.layerGroup(markers)
        this.markerLayer.addTo(this.map)
    }

    render() {
        return (
            <div className='map-container'>
                <div id='map' className='map' />
                {!this.state.loading &&
                    <ClinicPanel
                        map={this.map}
                        addMarkersToMap={this.addMarkersToMap}
                        stateFeatureMap={this.stateFeatureMap}
                        stateLayerStyles={stateLayerStyles}
                    />
                }
            </div>
        )
    }
}

export default Map

const mapOptions = {
    center: [51.163391, 10.447719], // center germany
    maxBounds: leaflet.latLngBounds(
        leaflet.latLng(46.64866337238187, 3.0896506214459727), // south-west corner
        leaflet.latLng(55.324431504298225, 17.76420022952559) // north-east corner
    ),
    zoom: 6, // initial zoom
    maxZoom: 18,
    minZoom: 6,
}

const stateLayerStyles = {
    focusedStyle: {
        weight: 2.5,
        color: palette.alarmRed,
        fillColor: 'transparent',
    },
    hoverStyle: {
        fillColor: '#569cb7',
        fillOpacity: 0.35
    },
    normalStyle: {
        weight: 1.5,
        color: 'rgb(120,120,120)',
        fillColor: 'rgba(120,120,120, 0.35)',
    }
}