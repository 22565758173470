import React from 'react'
import styled from 'styled-components'
import makeAnimated from 'react-select/animated'

// COMPONENTS
import Select from 'react-select'

// STYLE
import { palette } from '../../styles/colors'
import { BoldFont } from '../../styles/fonts'

const Dropdown = props => {
  const {
    width,
    zIndex,
    containerStyle,
    animated,
    valueField,
    labelField,
    labelText,
    isDisabled
  } = props

  const selectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: palette.primaryLight,
      primary25: palette.gray,
      primary50: palette.grayDark
    }
  })

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : palette.blackText,
      cursor: 'pointer'
    }),
    menu: provided => ({
      ...provided,
      zIndex: zIndex ?? 1
    }),
    control: provided => ({
      ...provided,
      cursor: 'pointer'
    }),
    container: provided => ({
      ...provided,
      width: labelText ? '100%' : width,
      ...containerStyle
    })
  }

  const select = (
    <Select
      {...props}
      components={animated ? makeAnimated() : void 0}
      noOptionsMessage={() => 'Keine Optionen'}
      getOptionLabel={labelField ? option => option[labelField] : undefined}
      getOptionValue={valueField ? option => option[valueField] : undefined}
      styles={selectStyles}
      theme={selectTheme}
    />
  )

  return labelText ? (
    <LabelContainer width={width}>
      <BoldFont style={{ marginBottom: '4px', opacity: isDisabled ? 0.4 : 1 }}>
        {labelText}
      </BoldFont>
      {select}
    </LabelContainer>
  ) : (
    select
  )
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`

export default Dropdown
