import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import Dropdown from '../../../ui-elements/Dropdown'
import Checkbox from '../../../ui-elements/Checkbox'

// ACTIONS
import { setFilter } from '../../../../redux/actions/clinicActions'

const options = [
  { sortBy: 'lastUpdate', label: 'Letztes Update' },
  { sortBy: 'online', label: 'Aktive Stellen' },
  { sortBy: 'new_', label: 'Neue Stellen' },
  { sortBy: 'old', label: 'Alte Stellen' }
]

const SortBy = () => {
  const dispatch = useDispatch()
  const { sortBy, descending } = useSelector(
    state => state.clinics.sortByFilter
  )
  const handleFilterChange = (option, descending) => {
    dispatch(
      setFilter({
        sortByFilter: { sortBy: option ? option.sortBy : null, descending }
      })
    )
  }
  const handleDescendingChange = (descending, sortBy) => {
    dispatch(
      setFilter({
        sortByFilter: { descending, sortBy }
      })
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
      <Dropdown
        options={options}
        labelText='Sortieren'
        value={
          !sortBy ? null : options.find(option => option.sortBy === sortBy)
        }
        onChange={value => handleFilterChange(value, descending)}
        valueField='sortBy'
        placeholder='Sortieren nach...'
        isClearable
        isSearchable={false}
        width='100%'
        zIndex={100}
      />
      <Checkbox
        value={descending}
        onChange={value => handleDescendingChange(value, sortBy)}
        text='Absteigend'
        style={{ marginTop: '10px' }}
      />
    </div>
  )
}

export default SortBy
