import React from 'react'
import { useSelector } from 'react-redux'

// COMPONENTS
import NumberIndicator from '../../ui-elements/NumberIndicator'
import CrawlButton from '../../CrawlButton'
import CustomerIcon from '../../clinic-list/list-item/item-components/CustomerIcon'

// STYLES
import { PageSummaryContainer } from '../../../styles/page'

const PageHeader = ({ reload }) => {
  const currentClinic = useSelector(state => state.jobs.currentClinic)
  const { online, new_, old, name, customer } = currentClinic
  return (
    <div className='page-header'>
      <CustomerIcon customer={customer} size='29px' />
      <h1>{name}</h1>
      <PageSummaryContainer>
        <NumberIndicator margin='0 25px 0 0' number={online} text='Online' />
        <NumberIndicator margin='0 25px 0 0' number={new_} text='Neu' />
        <NumberIndicator margin='0 30px 0 0' number={old} text='Alt' />
        <CrawlButton
          clinic={currentClinic}
          reload={reload}
          style={{ width: '120px' }}
        />
      </PageSummaryContainer>
    </div>
  )
}

export default PageHeader
