import React from 'react'
import styled from 'styled-components'

// COMPONENTS
import Button from '../ui-elements/Button'

// ICONS
import { AiOutlineReload } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { palette } from '../../styles/colors'

const SubmitAbortReset = ({
   loading,
   handleSubmit,
   handleAbort,
   handleReset,
   borderType,
   style
}) => {
   return (
      <ButtonContainer borderType={borderType} style={style}>
         {handleSubmit &&
            <Button
               style={{ width: '130px', marginRight: '20px' }}
               color='green'
               loading={loading}
               text='Speichern'
               onClick={handleSubmit}
            />
         }
         {handleAbort &&
            <Button
               style={{ width: '130px', marginRight: '20px' }}
               color='red'
               text='Abbrechen'
               onClick={handleAbort}
            />
         }
         {handleReset && <ResetIcon onClick={handleReset} />}
      </ButtonContainer>
   )
}

const ResetIcon = ({ onClick }) => (
   <IconContainer onClick={onClick} className="feedback">
      <IconContext.Provider value={{ size: '33px', color: palette.blackText }}>
         <AiOutlineReload />
      </IconContext.Provider>
   </IconContainer>
)

export default SubmitAbortReset

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  ${({ borderType }) => {
      if (!borderType) return ''
      return `
         height: 100px;
         align-items: center;
         ${borderType === 'bottom' ? 'margin-bottom: 50px;' : ''}
         border-${borderType}: solid 1px ${palette.grayDark};
      `
   }}
`

const IconContainer = styled.div`
  margin-left: auto;
  width: 40px;
  cursor: pointer;
`
