import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import TextInput from '../../../ui-elements/TextInput'

// ACTIONS
import { setFilter } from '../../../../redux/actions/clinicActions'

const SearchClinic = () => {
  const searchKey = useSelector(state => state.clinics.searchKey)
  const dispatch = useDispatch()
  const handleChange = event => {
    dispatch(setFilter({ searchKey: event.target.value }))
  }

  return (
    <TextInput
      value={searchKey}
      onChange={handleChange}
      labelText='Klinik suchen'
      placeholder='Klinkname...'
      width='90%'
    />
  )
}

export default SearchClinic
