import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'

// ICONS
import { FaList, FaUser, FaSignOutAlt, FaMap } from 'react-icons/fa'

// COMPONENTS
import AppearTransition from '../AppearTransition'
import ClinicNav from './ClinicNav'
import DropdownMenu from '../ui-elements/DropdownMenu'

// STYLES
import { backgroundColors, palette } from '../../styles/colors'

// ACTIONS
import { logout } from '../../redux/actions/userActions'

const Navbar = ({ history }) => {
    const dispatch = useDispatch()
    const isLoggedIn = useSelector(state => state.users.isLoggedIn)

    const onLogout = () => {
        dispatch(logout())
    }

    const currentRoute = history.location.pathname
    return (
        <AppearTransition>
            <NavbarContainer>
                <div className='navbar-content'>
                    <Link to='/'>
                        <img
                            alt={'Valmedi-Logo'}
                            src={require('../../assets/valmedi-logo.png')}
                            style={{ height: '80px', width: '250px' }}
                        />
                    </Link>
                    {isLoggedIn && (
                        <ComponentContainer>
                            <ClinicNav style={{ marginRight: '15px' }} />
                            <DropdownMenu
                                currentRoute={currentRoute}
                                content={[
                                    { type: 'link', route: '/clinics', icon: <FaList />, label: 'Übersicht' },
                                    { type: 'link', route: '/user', icon: <FaUser />, label: 'Nutzer' },
                                    { type: 'link', route: '/map', icon: <FaMap />, label: 'Karte' },
                                    { type: 'action', action: onLogout, icon: <FaSignOutAlt />, label: 'Logout' },
                                ]}
                                width='160px'
                                height='36px'
                            />
                        </ComponentContainer>
                    )}
                </div>
                <Footer />
            </NavbarContainer>
        </AppearTransition >
    )
}

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background: linear-gradient(
    to bottom,
    ${palette.primaryLight},
    ${palette.primary} 40%,
    ${palette.primaryDark} 80%
  );
`

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 17.5px;
  background: ${backgroundColors.gray};
`

export default withRouter(Navbar)
