import React from 'react'
import styled from 'styled-components'

// ICONS
import { IconContext } from 'react-icons'
import { FaFilter } from 'react-icons/fa'

// STYLES
import { palette } from '../styles/colors'

const Header = ({ text }) => {
    return (
        <Container>
            <IconContext.Provider value={{ size: '23px' }}>
                <FaFilter />
            </IconContext.Provider>
            &nbsp;{text}
        </Container>
    )
}

const Container = styled.h2`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  border-bottom: solid 1px ${palette.grayDark};
`

export default Header
