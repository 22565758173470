import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// STYLES
import './styles/css/index.css'
import './styles/css/button.css'
import './styles/css/transitions.css'
import './styles/css/feedback.css'
import './styles/css/tooltip.css'
import './styles/css/leaflet.css'
import './styles/css/map.css'

// REDUX
import { Provider } from 'react-redux'
import { store } from '../src/redux/store'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
