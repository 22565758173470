import React from 'react'

// STYLES
import { BoldFont } from '../../../../styles/fonts'

const Name = ({ name, style }) => {
  return <BoldFont style={style}>{name}</BoldFont>
}

export default Name
