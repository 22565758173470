import styled from 'styled-components'
import { NormalFont } from '../../styles/fonts'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 120;
  position: fixed;
  top: 50vh;
  left: 50vw;
  margin-left: -${({ width }) => width / 2 + 'px'};
  margin-top: -${({ height }) => height / 2 + 'px'};
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 80%;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  align-items: center;
  justify-content: ${({ center, row }) =>
        center || row ? 'flex-start' : 'flex-end'};
  flex: 0.85;
  width: 100%;
`

export const MessageContainer = styled(NormalFont)`
  display: flex;
  align-items: center;
  text-align: center;
  flex: 1;
  width: 70%;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 2;
  width: 100%;
`
