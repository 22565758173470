import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import TextInput from '../../../ui-elements/TextInput'

// ACTIONS
import { setFilter } from '../../../../redux/actions/jobActions'

const SearchId = () => {
    const searchKeyId = useSelector(state => state.jobs.searchKeyId)
    const dispatch = useDispatch()
    const handleChange = event => {
        dispatch(
            setFilter({
                searchKeyId: event.target.value
            })
        )
    }

    return (
        <TextInput
            labelText='ID suchen'
            placeholder='Valmedi-ID...'
            width='90%'
            value={searchKeyId}
            onChange={handleChange}
        />
    )
}

export default SearchId
