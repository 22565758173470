import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// COMPONENTS
import Dropdown from '../ui-elements/Dropdown'

// ACTIONS
import { getJobs } from '../../redux/actions/jobActions'
import { getClinicOptions } from '../../redux/actions/clinicActions'

class ClinicNav extends Component {
   constructor(props) {
      super(props)
      this.state = {
         expanded: false
      }
   }

   routeJobs = ({ _id: clinicId }) => {
      const { location, replace, push } = this.props.history
      if (location.pathname === '/jobs' && location.state.clinicId !== clinicId) {
         this.props.getJobs(clinicId)
         replace('/jobs', { clinicId })
      } else {
         push({ pathname: `/jobs`, state: { clinicId } })
      }
   }

   UNSAFE_componentWillMount() {
      this.props.getClinicOptions()
   }

   render() {
      return (
         <Dropdown
            options={this.props.clinicOptions}
            value={null}
            onChange={value => this.routeJobs(value)}
            labelField='name'
            valueField='_id'
            placeholder='Klinik suchen...'
            zIndex={100}
            width='230px'
            isSearchable
            containerStyle={this.props.style}
         />
      )
   }
}

const mapStateToProps = state => {
   return {
      clinicOptions: state.clinics.clinicOptions
   }
}

const mapDispatchToProps = dispatch => {
   return {
      getJobs: clinicId => dispatch(getJobs(clinicId)),
      getClinicOptions: () => dispatch(getClinicOptions())
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withRouter(ClinicNav))
