import React from 'react'
import styled from 'styled-components'

const NoElements = () => {
   return (
      <MessageContainer>
         <Message>Keine Ergebnisse ...</Message>
      </MessageContainer>
   )
}

const MessageContainer = styled.div`
  width: 100%;
  height: 70px;
  background: white;
`

const Message = styled.h2`
  margin-left: 25px;
  margin-top: 15px;
`

export default NoElements
