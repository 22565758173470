import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import CreateUserForm from '../forms/CreateUserForm'

// COMPONENTS
import Modal from './Modal'

// ACTIONS
import { toggleCreateUserModal } from '../../redux/actions/uiActions'

const CreateUserModal = () => {
    const dispatch = useDispatch()
    const closeModal = () => dispatch(toggleCreateUserModal())
    const modalOpen = useSelector(state => state.ui.createUserModalOpen)
    return (
        <Modal show={modalOpen} onClose={closeModal}>
            <ModalContainer
                width={450}
                height={400}
            >
                <CreateUserForm />
            </ModalContainer>
        </Modal>
    )
}

const ModalContainer = styled.div`
  z-index: 100;
  overflow-y: scroll;
  padding: 21px 21px 0 21px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  margin-left: -${({ width }) => width / 2 + 'px'};
  margin-top: -${({ height }) => height / 2 + 'px'};
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`

export default CreateUserModal
