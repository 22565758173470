import React from 'react'
import styled from 'styled-components'

// COMPONENTS
import CustomerIcon from './item-components/CustomerIcon'
import CrawlButton from '../../CrawlButton'
import JobSummary from './item-components/JobSummary'
import GoToJobs from './item-components/GoToJobs'
import Name from './item-components/Name'
import Edit from './item-components/Edit'
import AsignUser from './item-components/AsignUser'

// STYLES
import { backgroundColors, palette } from '../../../styles/colors'

const Clinic = ({ clinic, index }) => {
   const { _id: clinicId, name, customer } = clinic
   return (
      <Container index={index}>
         <TitleCell>
            <CustomerIcon customer={customer} size='20px' />
            <Name name={name} style={{ marginRight: '15px' }} />
            <SubContainer marginLeft>
               <AsignUser clinic={clinic} style={{ marginLeft: '15px' }} />
            </SubContainer>
         </TitleCell>
         <ButtonCell>
            <GoToJobs clinicId={clinicId} style={{ marginRight: '15px' }} />
            <Edit clinic={clinic} style={{ marginRight: '15px' }} />
            <CrawlButton
               clinic={clinic}
               small
               showLastUpdate
               style={{ width: '90px', marginRight: '15px' }}
            />
            <SubContainer marginLeft>
               <JobSummary clinic={clinic} style={{ marginLeft: '15px' }} />
            </SubContainer>
         </ButtonCell>
      </Container>
   )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 125px;
  background: ${({ index }) =>
      index % 2 !== 0 ? backgroundColors.gray : backgroundColors.white};
  border-bottom: solid 1px ${palette.grayDark};
  height: ${({ height }) => height};
`

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => (marginLeft ? 'auto' : 'none')};
  width: 36%;
  height: 100%;
  border-left: solid 0.5px ${palette.gray};
`

const ButtonCell = styled.div`
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  padding-left: 25px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`

const TitleCell = styled(ButtonCell)`
  height: 50%;
  border-bottom: solid 0.5px ${palette.gray};
`

export default Clinic
