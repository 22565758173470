// HELPER
import { sortListBy, filterJobs } from '../../helper/helper'

import {
    APPLY_FILTERS,
    UPDATE_JOB,
    REMOVE_JOB,
    UPDATE_CURRENT_CLINIC,
    TOGGLE_JOBS_PAGE_LOADING,
    RESET_FILTERS,
    SET_JOB_STATE
} from '../actions/jobActions'

const defaultFilters = {
    searchKeyId: '',
    searchKeyTitle: '',
    sortByFilter: {
        sortBy: null,
        descending: true
    },
    statusFilter: null
}

const initialState = {
    loading: false,
    currentClinic: {},
    jobs: [],
    ...defaultFilters
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_JOB_STATE:
            return {
                ...state,
                ...action.payload
            }
        case TOGGLE_JOBS_PAGE_LOADING:
            return {
                ...state,
                loading: !state.loading
            }
        case RESET_FILTERS:
            return {
                ...state,
                ...defaultFilters
            }
        case APPLY_FILTERS:
            const jobs = filterJobs(state)
            const { sortBy, descending } = state.sortByFilter
            sortBy && sortListBy(jobs, sortBy, descending)
            return {
                ...state,
                jobs
            }
        case UPDATE_JOB:
            return {
                ...state,
                jobs: state.jobs.map(job => {
                    if (job._id === action.job._id) {
                        return action.job
                    }
                    return job
                })
            }
        case REMOVE_JOB:
            return {
                ...state,
                jobs: state.jobs.filter(job => job._id !== action._id)
            }
        case UPDATE_CURRENT_CLINIC:
            const currentClinic = { ...state.currentClinic }
            for (let key in action.updates) {
                currentClinic[key] += action.updates[key]
            }
            return {
                ...state,
                currentClinic
            }
        default:
            return state
    }
}

export default reducer
