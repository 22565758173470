import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// ICONS
import { IoIosAlert } from 'react-icons/io'
import { IconContext } from 'react-icons'

// COMPONENTS
import Modal from './Modal'
import Button from '../ui-elements/Button'

// STYLES
import { palette } from '../../styles/colors'
import {
    ModalContainer,
    HeaderContainer,
    MessageContainer,
    ButtonContainer
} from './style'

// ACTIONS
import { closeConfirm } from '../../redux/actions/uiActions'

const ConfirmModal = () => {
    const modalOpen = useSelector(state => state.ui.confirmModalOpen)
    const { text, actionText } = useSelector(state => state.ui.confirmMessage)
    const confirmAction = useSelector(state => state.ui.confirmAction)

    const dispatch = useDispatch()
    const closeModal = () => dispatch(closeConfirm())
    const closeAndConfirm = () => {
        confirmAction()
        closeModal()
    }

    return (
        <Modal show={modalOpen} onClose={closeModal}>
            <ModalContainer width={450} height={310}>
                <HeaderContainer>
                    <IconContext.Provider value={{ size: '60px', color: palette.red }}>
                        <IoIosAlert />
                    </IconContext.Provider>
                    <h2>Bitte Bestätigen</h2>
                </HeaderContainer>
                <MessageContainer>{text}</MessageContainer>
                <ButtonContainer>
                    <Button
                        style={{ flex: 1, borderRadius: '4px 0 0 5px', height: '42.5px' }}
                        color='blue'
                        text={actionText}
                        onClick={closeAndConfirm}
                    />
                    <Button
                        style={{ flex: 1, borderRadius: '0 4px 5px 0', height: '42.5px' }}
                        color='red'
                        text='Abbrechen'
                        onClick={closeModal}
                    />
                </ButtonContainer>
            </ModalContainer>
        </Modal>
    )
}

export default ConfirmModal
