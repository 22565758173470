import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'

// STYLES
import { palette } from '../../styles/colors'

// ICONS
import { IconContext } from 'react-icons'
import { FaChevronDown, FaSuitcase } from 'react-icons/fa'

const DropdownMenu = ({ content, width, height, currentRoute, placeholder }) => {
    const getStyledIcon = icon => {
        return (
            <IconContext.Provider value={{ size: '16px', color: palette.primaryDark }}>
                {icon}
            </IconContext.Provider>
        )
    }

    const renderContent = ({ type, label, icon, route, action }) => {
        if (type === 'link') {
            if (route === currentRoute)
                return null
            return (
                <StyledLink to={route} key={label}>
                    <StyledOption>
                        {getStyledIcon(icon)}
                        &nbsp;&nbsp;{label}
                    </StyledOption>
                </StyledLink>
            )
        }
        return (
            <StyledOption onClick={action} key={label}>
                {getStyledIcon(icon)}
                &nbsp;&nbsp;{label}
            </StyledOption>
        )
    }
    const getCurrent = () => {
        const current = content.filter(({ route }) => route === currentRoute)
        if (current.length === 0) return (
            <StyledElement>
                {getStyledIcon(<FaSuitcase />)}
                &nbsp;&nbsp;Stellen
            </StyledElement>
        )
        const { label, icon } = current[0]
        return (
            <div>
                <StyledElement>
                    {getStyledIcon(icon)}
                    &nbsp;&nbsp;{label}
                </StyledElement>
            </div>
        )
    }

    if (content.length === 0) return <div />

    return (
        <Container width={width} maxHeight={height}>
            <HeaderContainer height={height} >
                <CurrentContainer>
                    {currentRoute ? getCurrent() : placeholder}
                </CurrentContainer>
                <IconContainer>
                    <IconContext.Provider value={{ size: '20px', color: 'rgb(195, 195, 195)' }}>
                        <FaChevronDown />
                    </IconContext.Provider>
                </IconContainer>
            </HeaderContainer>
            <ContentContainer childHeight={height} >
                {content.map(renderContent)}
            </ContentContainer>
        </Container >
    )
}

export default withRouter(DropdownMenu)


const Container = styled.div`
    display: inline-block;
    max-height: ${({ maxHeight }) => maxHeight};
    width: ${({ width }) => width};
    cursor: pointer;
`

const ContentContainer = styled.div`
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
    z-index: 100;
    position: relative;
    display: none;
    background: white;
    ${Container}:hover & {
        border-radius: 0 0 3px 3px;
        border: solid 1px ${palette.gray};
        display: block;
    }
`

const HeaderContainer = styled.div`
    display: flex;
    border-radius: 3px;
    height: ${({ height }) => height};
    border: solid 1px ${palette.gray};
    width: 100%;
    background: white;
    ${Container}:hover & {
        border-radius: 3px 3px 0 0;
        border-bottom: none;
    }
`

const CurrentContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    padding-left: 10px;
    height: 100%;
    border-right: solid 1px rgb(195, 195, 195);
`
const IconContainer = styled.div`
    display: flex;
    transition: transform 0.3s ease;
    transform: rotate(180deg);
    ${Container}:hover & {
        transform: rotate(0deg);
    }
    align-items: center;
    justify-content:center;
    height: 100%;
    width: 40px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
`

const StyledElement = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${palette.blackText};
  font-weight: 600;
  font-size: 12pt;
  width: 100%;
`

const StyledOption = styled(StyledElement)`
    padding: 10px;
    &:hover {
        background: ${palette.gray};
    }
`