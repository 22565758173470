import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import Clinic from './list-item/Clinic'
import UserClinic from './list-item/UserClinic'
import FilterPanel from './filter-panel/ClinicFilterPanel'
import ListHeader from './ListHeader'
import NoElements from '../NoElements'

class ClinicList extends PureComponent {
  render () {
    const { clinics, userPage } = this.props
    const reduceFunction = userPage
      ? function (reducer, clinic) {
          if (clinic.show) {
            reducer.index++
            reducer.elements.push(
              <UserClinic
                key={clinic._id}
                index={reducer.index}
                clinic={clinic}
              />
            )
          }
          return reducer
        }
      : function (reducer, clinic) {
          if (clinic.show) {
            reducer.index++
            reducer.elements.push(
              <Clinic key={clinic._id} index={reducer.index} clinic={clinic} />
            )
          }
          return reducer
        }

    const renderClinics = function () {
      const rendered = clinics.reduce(reduceFunction, {
        elements: [],
        index: -1
      })
      return rendered.elements.length === 0 ? <NoElements /> : rendered.elements
    }
    return (
      <div className='list-container'>
        <div className='clinic-list'>
          <ListHeader />
          {renderClinics()}
        </div>
        <FilterPanel userPage={userPage} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    clinics: state.clinics.clinics
  }
}

export default connect(mapStateToProps, null)(ClinicList)
