import styled from 'styled-components'
import { palette } from '../../styles/colors'

// NEW

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`

export const Heading = styled.h2`
   display: flex;
   align-items: center;
   align-text: left;
   width: 100%;
   border-bottom: solid 1px ${palette.grayDark};
   margin: 30px 0 30px 0;
`

export const SubHeading = styled.h3`
   display: flex;
   align-items: center;
   width: 100%;
   align-text: left;
   height: 40px;
   border-bottom: solid 1px ${palette.grayDark};
   margin: 10px 0 35px 0;
`
