import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import levenshtein from 'js-levenshtein'

// COMPONENTS
import Modal from './Modal'
import MatchedJobs from '../../components/job-list/list-item/MatchedJobs'

// ICONS
import { IconContext } from 'react-icons'
import { MdCancel } from 'react-icons/md'

// STYLES
import { palette } from '../../styles/colors'
import { BoldFont } from '../../styles/fonts'

// ACTIONS
import { TOGGLE_MODAL } from '../../redux/actions/uiActions'

const SwapIdModal = () => {
    const modalOpen = useSelector(state => state.ui.swapIdModalOpen)
    const jobs = useSelector(state => state.jobs.jobs)
    const dispatch = useDispatch()
    const closeModal = () => dispatch({ type: TOGGLE_MODAL, modal: 'swapId' })
    const matchJobs = () => {
        const oldJobs = jobs.filter(({ status }) => status === -1)
        const newJobs = jobs.filter(({ status }) => status === 1)
        const combinations = []
        for (let oldJob of oldJobs) {
            for (let newJob of newJobs) {
                const distance = levenshtein(
                    newJob.title.toLowerCase().replace(/\s+/g, ' '),
                    oldJob.title.toLowerCase().replace(/\s+/g, ' ')
                )
                if (distance <= 3) {
                    combinations.push([newJob, oldJob, distance])
                }
            }
        }

        return combinations.sort((a, b) => {
            if (a[2] < b[2]) return -1
            if (a[2] > b[2]) return 1
            return 0
        }).map(combination => {
            const [newJob, oldJob, _] = combination
            return <MatchedJobs newJob={newJob} oldJob={oldJob} key={newJob._id + oldJob._id} />
        })
    }

    const matchedJobs = modalOpen ? matchJobs() : []

    return (
        <Modal show={modalOpen} onClose={closeModal}>
            <ModalContainer>
                {matchedJobs.length > 0 ? (
                    <React.Fragment>
                        <MatchesHeader>Es konnten {matchedJobs.length} ähnliche Stellentitel für diese Klinik gefunden werden</MatchesHeader>
                        {matchedJobs}
                    </React.Fragment>

                ) : (
                    <MatchesHeader>Es konnten keine ähnlichen Stellentitel für diese Klinik gefunden werden ...</MatchesHeader>
                )}
            </ModalContainer>
            <CloseButton className='feedback' onClick={closeModal}>
                <IconContext.Provider value={{ size: '70px', color: palette.gray }}>
                    <MdCancel />
                </IconContext.Provider>
            </CloseButton>
        </Modal>
    )
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  position: fixed;
  left: 50vw;
  margin-left: -425px;
  width: 850px;
  max-height: 100vh;
  overflow-y: scroll;
  background: transparent;
  box-shadow: none;
  border-radius: 8px;
`

export const CloseButton = styled.div`
  position: absolute;
  left: 50vw;
  margin-left: 445px;
  top: 115px;
  z-index: 110;
  cursor: pointer;
`

export const MatchesHeader = styled(BoldFont)`
  margin-top: 100px;
  margin-bottom: 20px;
  color: white;
  font-size: 20pt;
`

export default SwapIdModal
