import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import TextInput from '../ui-elements/TextInput'
import SubmitAbortReset from './SubmitAbortReset'

// STYLES
import { SubHeading, InputRow } from './style'

// HELPERS
import { idIsValid } from '../../helper/validation'

// ACTIONS
import { closeEditId } from '../../redux/actions/uiActions'

class EditIdForm extends Component {
   constructor(props) {
      super(props)
      this.state = {
         id: props.job.valmediId[0],
         highlight: false
      }
      this.textInputRef = React.createRef()
   }

   handleReset = () => {
      this.setState({ id: this.props.job.valmediId[0] })
   }

   handleIdChange = ({ target }) => {
      this.setState({
         ...(this.state.highlight ? { highlight: false } : {}),
         id: target.value
      })
   }

   handleSubmit = () => {
      const { id } = this.state
      const {
         closeAndUpdate,
         job: { _id, clinicId }
      } = this.props
      if (!idIsValid(id)) {
         this.setState({ highlight: true })
         this.textInputRef.current.focus()
      } else {
         closeAndUpdate({
            _id,
            clinicId,
            update: { valmediId: [id] },
            noLog: true
         })
      }
   }

   render() {
      const { id, highlight } = this.state
      const { closeNoUpdate } = this.props
      return (
         <React.Fragment>
            <SubHeading>Bitte gib eine neue ID ein</SubHeading>
            <InputRow style={{ marginBottom: '40px' }}>
               <TextInput
                  value={id}
                  onChange={this.handleIdChange}
                  highlight={highlight}
                  reference={this.textInputRef}
                  labelText='Valmedi-ID:'
                  placeholder='ID eingeben'
                  width='100%'
               />
            </InputRow>
            <SubmitAbortReset
               handleAbort={closeNoUpdate}
               handleSubmit={this.handleSubmit}
               handleReset={this.handleReset}
               borderType='top'
            />
         </React.Fragment>
      )
   }
}

const mapStateToProps = state => {
   return {
      job: state.ui.jobToEdit,
      toggleLoading: state.ui.toggleLoading
   }
}

const mapDispatchToProps = dispatch => {
   return {
      closeAndUpdate: (update = null) => dispatch(closeEditId(update)),
      closeNoUpdate: () => dispatch(closeEditId(null))
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIdForm)
