import React from 'react'
import styled from 'styled-components'
import JobInfo from '../../../ui-elements/JobInfo'

const JobSummary = ({ clinic: { online, new_, old }, style }) => {
  return (
    <Container style={{ ...style }}>
      <JobInfo
        number={online}
        color='green'
        size={16}
        style={{ marginRight: '27px' }}
      />
      <JobInfo
        number={new_}
        color='yellow'
        size={16}
        style={{ marginRight: '27px' }}
      />
      <JobInfo
        number={old}
        color='red'
        size={16}
        style={{ marginRight: '27px' }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default JobSummary
