import React, { Component } from "react";
import { connect } from "react-redux";
import leaflet from "leaflet";

// COMPONENTS
import Button from "../ui-elements/Button";
import SearchClinic from "../clinic-list/filter-panel/filter/SearchClinic";
import FilterUsers from "../clinic-list/filter-panel/filter/FilterUsers";
import FilterCustomers from "../clinic-list/filter-panel/filter/FilterCustomers";
import FilterStates from "../clinic-list/filter-panel/filter/FilterStates";
import { blueIcon, blueIconBig, greenIcon, greenIconBig } from "./leaflet-markers";

// STYLES
import { palette } from "../../styles/colors";

// ACTIONS
import { getClinics, setFilter } from "../../redux/actions/clinicActions";

class ClinicPanel extends Component {
    componentWillMount() {
        // on component mount fetch data into redux state
        this.props.getClinics();
    }

    componentWillReceiveProps(nextProps) {
        /**
         * filter actions will trigger new props through redux.
         * once this happens markers have to refreshed
         */
        if (nextProps.stateFilter === "all" && this.props.stateFilter !== "all") {
            const current = this.props.stateFilter;
            this.props.stateFeatureMap[current].setStyle(
                this.props.stateLayerStyles.normalStyle
            );
        }
        this.setMarkers();
    }

    componentDidMount() {
        /**
         * once the component did mount (map is initialized in parent)
         * set functions for responsive states
         */
        for (let state in this.props.stateFeatureMap) {
            const stateLayer = this.props.stateFeatureMap[state];
            stateLayer.on({
                click: (e) => this.onClickState(e, stateLayer.feature),
                mouseover: (e) => this.onMouseOverState(e, stateLayer.feature),
                mouseout: (e) => this.onMouseOutState(e, stateLayer.feature),
            });
        }
    }

    onClickState = (e, { id }) => {
        const current = this.props.stateFilter;
        this.props.map.fitBounds(e.target.getBounds());
        if (current !== id) {
            e.target.setStyle(this.props.stateLayerStyles.focusedStyle);
            e.target.bringToFront();
            if (current !== "all") {
                this.props.stateFeatureMap[current].setStyle(
                    this.props.stateLayerStyles.normalStyle
                );
            }
            this.props.setStateFilter(id);
        }
    };

    onMouseOverState = (e, { id }) => {
        const current = this.props.stateFilter;
        if (current !== id) {
            e.target.setStyle(this.props.stateLayerStyles.hoverStyle);
        }
    };

    onMouseOutState = (e, { id }) => {
        const current = this.props.stateFilter;
        if (current !== id) {
            e.target.setStyle(this.props.stateLayerStyles.normalStyle);
        }
    };

    setMarkers = () => {
        const markers = this.props.clinics.reduce(this.clinicReducer, []);
        this.props.addMarkersToMap(markers);
    };

    clinicReducer = (markers, clinic) => {
        if (!clinic.show) return markers
        const iconSmall = clinic.customer ? greenIcon : blueIcon;
        const iconBig = clinic.customer ? greenIconBig : blueIconBig;
        const locationMarkers = clinic.locations.map((location) => {
            const marker = leaflet.marker(location.latLng, { icon: iconSmall });
            marker.map = this.props.map;
            marker.bindPopup(
                `<b style="text-decoration: underline;">${clinic.name}</b><br/>
           Standort:&nbsp;<b>${location.name}</b><br/>`
            );
            marker.on("mouseover", function () {
                this.openPopup();
                this.polygon = leaflet
                    .polygon(
                        this.connectedMarkers.map((m) => {
                            m.setIcon(iconBig);
                            return m.getLatLng();
                        }),
                        { color: palette.primaryDark, fillColor: "transparent" }
                    )
            });
            marker.on("mouseout", function () {
                this.closePopup();
                this.polygon.remove(this.map);
                for (let connectedMarker of this.connectedMarkers) {
                    connectedMarker.setIcon(iconSmall);
                }
            });
            marker.on("click", function () {
                this.map.fitBounds(this.polygon.getBounds());
            });
            return marker;
        });
        for (let marker of locationMarkers) {
            marker.connectedMarkers = locationMarkers;
        }
        markers.push(...locationMarkers);
        return markers;
    };

    render() {
        return (
            <div className="map-filter-panel">
                <SearchClinic />
                <FilterUsers />
                <FilterStates />
                <FilterCustomers />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stateFilter: state.clinics.stateFilter,
        clinics: state.clinics.clinics,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClinics: () => dispatch(getClinics()),
        setStateFilter: (stateId) => dispatch(setFilter({ stateFilter: stateId })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicPanel);

const stateOptions = [
    {
        label: "Baden-Württemberg",
        value: "BW",
    },
    {
        label: "Bayern",
        value: "BY",
    },
    {
        label: "Berlin",
        value: "Berlin",
    },
    {
        label: "Brandenburg",
        value: "BB",
    },
    {
        label: "Bremen",
        value: "HB",
    },
    {
        label: "Hamburg",
        value: "HH",
    },
    {
        label: "Hessen",
        value: "HE",
    },
    {
        label: "Mecklenburg-Vorpommern",
        value: "MV",
    },
    {
        label: "Niedersachsen",
        value: "NDS",
    },
    {
        label: "Nordrhein-Westfahlen",
        value: "NRW",
    },
    {
        label: "Rheinland-Pfalz",
        value: "RP",
    },
    {
        label: "Saarland",
        value: "SL",
    },
    {
        label: "Sachsen-Anhalt",
        value: "SA",
    },
    {
        label: "Sachsen",
        value: "SN",
    },
    {
        label: "Schleswig-Holstein",
        value: "SH",
    },
    {
        label: "Thüringen",
        value: "TH",
    },
];

const mapOptions = {
    center: [51.163391, 10.447719], // center germany
    maxBounds: leaflet.latLngBounds(
        leaflet.latLng(46.64866337238187, 3.0896506214459727), // south-west corner
        leaflet.latLng(55.324431504298225, 17.76420022952559) // north-east corner
    ),
    zoom: 6, // initial zoom
    maxZoom: 18,
    minZoom: 6,
};

const bedOptions = [
    { label: "Bis 250", value: { min: 0, max: 250 } },
    { label: "251 Bis 500", value: { min: 251, max: 500 } },
    { label: "501 bis 1000", value: { min: 501, max: 1000 } },
    { label: "Über 1000", value: { min: 1001, max: 5000 } },
];
