export const palette = {
    primary: '#569cb7',
    primaryLight: '#78b0c5',
    primaryOpaque: '#DDEBF0',
    primaryDark: 'rgb(70,134,159)',
    primaryVeryDark: 'rgb(50,114,139)',
    primaryDarkOpaque: 'rgba(70,134,159, 0.65)',
    yellow: 'rgb(231,175,95)',
    yellowLight: 'rgb(241,185,105)',
    yellowDark: 'rgb(202,136,52)',
    green: 'rgb(88,185,159)',
    greenLight: 'rgb(78,175,149)',
    greenDark: 'rgb(74,157,135)',
    red: 'rgb(173,75,73)',
    redLight: 'rgb(206,103,100)',
    redDark: 'rgb(171,57,48)',
    gray: 'rgb(225,225,225)',
    garyLight: 'rgb(247,247,247)',
    grayDark: 'rgb(200,200,200)',
    grayVeryDark: 'rgb(120,120,120)',
    alarmRed: 'rgba(217, 48, 37, 0.75)',
    blackText: 'rgb(51,51,51)'
}

export const backgroundColors = {
    white: 'rgb(255,255,255)',
    gray: 'rgb(246,246,246)',
    primary: palette.primary,
    focused: 'rgba(86, 156, 183, 0.175)'
}
