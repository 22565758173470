import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

// ICONS
import { FaPencilAlt } from 'react-icons/fa'

// COMPONENTS
import Button from '../../../ui-elements/Button'

// ACTIONS
import { openEditClinic } from '../../../../redux/actions/uiActions'

const Edit = ({ clinic, clinic: { active }, style }) => {
  const dispatch = useDispatch()
  return (
    <Container style={style}>
      <Button
        onClick={() => dispatch(openEditClinic(clinic))}
        disabled={!active}
        text='Bearbeiten'
        color={active ? 'blue' : 'gray'}
        small
      >
        <FaPencilAlt />
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default Edit
