import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// ICONS
import { IconContext } from 'react-icons'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

// STYLES
import { palette } from '../../styles/colors'
import { NormalFont } from '../../styles/fonts'

const Checkbox = ({ value, onChange, text, style }) => {
  const [checked, setChecked] = useState(false)
  const [hover, setHover] = useState(false)
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    setChecked(value)
  }, [value])

  const toggleHover = () => setHover(prev => !prev)
  const toggleClick = () => setClicked(prev => !prev)

  const handleClick = () => {
    setChecked(prev => !prev)
    onChange(!checked)
  }

  const Icon = (
    <IconContext.Provider value={{ color: palette.primary, size: 25 }}>
      {checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
    </IconContext.Provider>
  )

  return (
    <div style={{ display: 'flex', ...style }}>
      <IconContainer
        hover={hover}
        clicked={clicked}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onMouseDown={toggleClick}
        onMouseUp={toggleClick}
        onClick={handleClick}
      >
        {Icon}
      </IconContainer>
      {text && <NormalFont style={{ marginLeft: '5px' }}>{text}</NormalFont>}
    </div>
  )
}

const IconContainer = styled.div`
  cursor: pointer;
  transform: ${({ hover, clicked }) =>
    clicked ? 'scale(1.2)' : hover ? 'scale(1.1)' : 'none'};
`
export default Checkbox
