import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaKey } from 'react-icons/fa'

// ICONS
import { FaList, FaUser, FaMap } from 'react-icons/fa'

// COMPONENTS
import NumberIndicator from '../../ui-elements/NumberIndicator'
import Button from '../../ui-elements/Button'
import ClinicsSummary from './ClinicsSummary'
import CreateUserButton from '../../CreateUserButton'
import DropdownMenu from '../../ui-elements/DropdownMenu'

// STYLES
import { PageSummaryContainer } from '../../../styles/page'

// ACTIONS
import { postError } from '../../../redux/actions/uiActions'

const PageHeader = ({ userPage }) => {
    const dispatch = useDispatch()
    const userName = useSelector(state => state.users.userName)
    const nActive = useSelector(state => state.clinics.clinicsInformation.nActive)
    if (userPage) {
        return (
            <div className='page-header'>
                <h1>Übersicht - {userName}</h1>
                <PageSummaryContainer>
                    <NumberIndicator
                        margin='0 30px 0 0'
                        number={nActive}
                        text='Zugewiesene Kliniken'
                    />
                    <Button
                        text='Nutzerdaten ändern'
                        color='green'
                        onClick={() =>
                            dispatch(
                                postError('Diese Funktion steht noch nicht zu Verfügung')
                            )
                        }
                        style={{ width: '190px' }}
                    >
                        <FaKey />
                    </Button>
                </PageSummaryContainer>
            </div>
        )
    } else {
        return (
            <div className='page-header'>
                <h1>Klinik Übersicht</h1>
                <PageSummaryContainer>
                    <ClinicsSummary />
                    <CreateUserButton />
                </PageSummaryContainer>
            </div>
        )
    }
}

export default PageHeader
