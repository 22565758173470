import {
  SET_USER_OPTIONS,
  SET_USER_DATA,
  TOGGLE_LOGIN_LOADING
} from '../actions/userActions'

const initialState = {
  loginLoading: false,
  isLoggedIn: false,
  token: null,
  admin: undefined,
  userId: null,
  userClinics: [],
  userOptions: [],
  userName: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
    case SET_USER_OPTIONS:
      action.type = undefined
      return {
        ...state,
        ...action
      }
    case TOGGLE_LOGIN_LOADING:
      return {
        ...state,
        loginLoading: !state.loginLoading
      }
    default:
      return state
  }
}

export default reducer
