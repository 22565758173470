import styled from 'styled-components'
import { palette } from './colors'

export const normalFont = {
  fontSize: '13pt',
  color: palette.blackText
}

export const boldFont = {
  fontWeight: 'bold',
  fontSize: '13pt',
  color: palette.blackText
}

export const NormalFont = styled.div`
  font-size: 13pt;
  color: ${({ color }) => (color ? color : palette.blackText)};
`

export const BoldFont = styled.div`
  font-size: 13pt;
  font-weight: bold;
  color: ${({ color }) => (color ? color : palette.blackText)};
`
