import React, { Component } from 'react'
import { connect } from 'react-redux'

// ICONS
import { FaUserPlus } from 'react-icons/fa'
import { IconContext } from 'react-icons'

// COMPONENTS
import SubmitAbortReset from './SubmitAbortReset'
import Checkbox from '../ui-elements/Checkbox'
import TextInput from '../ui-elements/TextInput'

// STYLES
import { palette } from '../../styles/colors'
import { SubHeading, InputRow } from './style'

// ACTIONS
import { toggleCreateUserModal } from '../../redux/actions/uiActions'

class CreateUserForm extends Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         noLogin: false,
         highlightEmail: false,
         highlightName: false,
         email: '',
         name: ''
      }
      this.emailInputRef = React.createRef()
      this.nameInputRef = React.createRef()
   }

   UNSAFE_componentWillReceiveProps() {
      this.setState({
         loading: false,
         noLogin: false,
         highlightEmail: false,
         highlightName: false,
         email: '',
         name: ''
      })
   }

   handleMailInput = event => {
      this.setState({
         email: event.target.value,
         ...(this.state.highlightEmail ? { highlightEmail: false } : {})
      })
   }
   handleNameInput = event => {
      this.setState({
         name: event.target.value,
         ...(this.state.highlightName ? { highlightName: false } : {})
      })
   }

   handleNoLogin = value => {
      this.setState({
         noLogin: value,
         ...(value ? { email: '' } : {})
      })
   }

   validateEmail = email => {
      var re = /\S+@\S+\.\S+/
      return re.test(email)
   }

   handleSubmit = () => {
      const { name, noLogin, email } = this.state
      const { closeAndCreate } = this.props
      const nameValid = name !== ''
      if (noLogin) {
         if (!nameValid) {
            this.nameInputRef.current.focus()
            this.setState({ highlightName: true })
         } else {
            this.setState({ loading: true }, () =>
               closeAndCreate({ name }, () => this.setState({ loading: false }))
            )
         }
      } else {
         const emailValid = this.validateEmail(email)
         if (!emailValid || !nameValid) {
            this.setState({
               highlightEmail: !emailValid,
               highlightName: !nameValid
            })
            if (!emailValid) this.emailInputRef.current.focus()
            else this.nameInputRef.current.focus()
         } else {
            this.setState({ loading: true }, () =>
               closeAndCreate({ email, name }, () =>
                  this.setState({ loading: false })
               )
            )
         }
      }
   }

   render() {
      const { closeNoCreate } = this.props
      const {
         loading,
         email,
         name,
         highlightEmail,
         highlightName,
         noLogin
      } = this.state
      return (
         <React.Fragment>
            <SubHeading>
               Nutzer erstellen&nbsp;&nbsp;
                        <IconContext.Provider
                  value={{ size: '19px', color: palette.primary }}
               >
                  <FaUserPlus />
               </IconContext.Provider>
            </SubHeading>
            <InputRow>
               <TextInput
                  width='100%'
                  labelText='E-Mail:'
                  reference={this.emailInputRef}
                  value={email}
                  highlight={highlightEmail && !noLogin}
                  loading={loading || noLogin}
                  disabled={loading || noLogin}
                  placeholder='E-Mail eingeben ...'
                  type='email'
                  onChange={this.handleMailInput}
               />
            </InputRow>
            <InputRow>
               <TextInput
                  width='100%'
                  labelText='Name:'
                  reference={this.nameInputRef}
                  loading={loading}
                  highlight={highlightName}
                  value={name}
                  disabled={loading}
                  placeholder='Name eingeben ...'
                  onChange={this.handleNameInput}
               />
            </InputRow>
            <InputRow>
               <Checkbox
                  text='Nutzer ohne Login'
                  value={noLogin}
                  onChange={this.handleNoLogin}
               />
            </InputRow>
            <SubmitAbortReset
               handleAbort={closeNoCreate}
               handleSubmit={this.handleSubmit}
               borderType='top'
            />
         </React.Fragment>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      closeNoCreate: () => dispatch(toggleCreateUserModal()),
      closeAndCreate: (newUser, toggleLoading) =>
         dispatch(toggleCreateUserModal(newUser, toggleLoading))
   }
}

export default connect(null, mapDispatchToProps)(CreateUserForm)
