import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

// COMPONENTS
import StatusIndicator from '../../../ui-elements/StatusIndicator'

// STYLES
import { palette } from '../../../../styles/colors'
import { BoldFont } from '../../../../styles/fonts'

// ACTIONS
import { setFilter } from '../../../../redux/actions/jobActions'

const JobFilter = () => {
  const dispatch = useDispatch()
  const statusFilter = useSelector(state => state.jobs.statusFilter)

  const handleClick = buttonStatus => {
    dispatch(
      setFilter({
        statusFilter: statusFilter === buttonStatus ? null : buttonStatus
      })
    )
  }

  return (
    <Container>
      <label style={{ marginBottom: '4px' }}>Status Filter</label>
      <ButtonContainer>
        <FilterButton
          buttonStatus={0}
          color='green'
          onClick={() => handleClick(0)}
          statusFilter={statusFilter}
        />
        <FilterButton
          buttonStatus={1}
          color='yellow'
          onClick={() => handleClick(1)}
          statusFilter={statusFilter}
        />
        <FilterButton
          buttonStatus={-1}
          color='red'
          onClick={() => handleClick(-1)}
          statusFilter={statusFilter}
        />
        <FilterButton
          buttonStatus={-2}
          color='gray'
          onClick={() => handleClick(-2)}
          statusFilter={statusFilter}
        />
      </ButtonContainer>
    </Container>
  )
}

const FilterButton = ({ buttonStatus, statusFilter, color, onClick }) => {
  const [hover, setHover] = useState(false)
  const [clicked, setClicked] = useState(false)
  const scale = clicked
    ? 1.5
    : statusFilter === buttonStatus
    ? 1.4
    : hover
    ? 1.15
    : 1
  return (
    <div
      style={{ transform: `scale(${scale})`, cursor: 'pointer' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setClicked(true)}
      onMouseUp={() => setClicked(false)}
      onClick={onClick}
    >
      <StatusIndicator size={20} color={color} />
    </div>
  )
}

const Container = styled(BoldFont)`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 5%;
  align-self: flex-start;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 36px;
  width: 100%;
  border: solid 1px ${palette.grayDark};
  border-radius: 3px;
`

export default JobFilter
