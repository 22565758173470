import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import EditClinicForm from '../forms/EditClinicForm'

// COMPONENTS
import Modal from './Modal'

// ACTIONS
import { closeEditClinic } from '../../redux/actions/uiActions'

const EditClinicModal = () => {
    const dispatch = useDispatch()
    const closeModal = () => dispatch(closeEditClinic())
    const modalOpen = useSelector(state => state.ui.clinicEditModalOpen)
    return (
        <Modal show={modalOpen} onClose={closeModal}>
            <ModalContainer
                width={700}
                maxHeight={window.innerHeight}
            >
                <EditClinicForm />
            </ModalContainer>
        </Modal>
    )
}

const ModalContainer = styled.div`
    position: fixed;
    z-index: 200;
    overflow-y: scroll;
    padding: 0 21px 0 21px;
    top: 30px;
    left: 50vw;
    margin-left: -${({ width }) => width / 2}px;
    max-height: ${({ maxHeight }) => maxHeight - 60}px;
    width: ${({ width }) => width}px;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
`

export default EditClinicModal
