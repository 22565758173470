export const idIsValid = id => {
   if (id === '' || isNaN(id) || id === '0') return false
   return true
}

export const validateText = (value, required, defaultValue = null) => {
   /*
   value : String --> text to validate
   required : Bool --> field required or not
   defaultValue : null | undefinded | '' | ...
   */
   if (required) return !value ? { valid: false } : { valid: true, value }
   return !value ? { valid: true, value: defaultValue } : { valid: true, value }
}

export const validateInt = (value, required, defaultValue = null) => {
   /*
   value : String --> integer to validate
   required : Bool --> field required or not
   defaultValue : null | undefinded | '' | ...
   */
   if (isNaN(value)) return { valid: false }
   if (required) return !value ? { valid: false } : { valid: true, value: parseInt(value) }
   return !value ? { valid: true, value: defaultValue } : { valid: true, value: parseInt(value) }
}
