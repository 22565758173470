import React, { Component } from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'

// COMPONENTS
import ShowIds from './item-components/ShowIds'
import Title from './item-components/Title'
import ActionButtons from './item-components/ActionButtons'
import JobStatus from './item-components/JobStatus'
import EditId from './item-components/EditId'
import GoToJob from './item-components/GoToJob'
import Focus from './item-components/Focus'

// STYLES
import { backgroundColors, palette } from '../../../styles/colors'
import { BoldFont } from '../../../styles/fonts'

class Job extends Component {
  constructor (props) {
    super(props)
    this.state = {
      focused: false
    }
  }

  toggleFocus = () => this.setState(prev => ({ focused: !prev.focused }))

  render () {
    const { index, job, style, showIds, noActions } = this.props
    const { focused } = this.state
    const { title, date, status, url } = job
    return (
      <JobContainer style={style} index={index} focused={focused}>
        <TitleCellContainer showIds={showIds}>
          <TitleContainer>
            {!noActions && (
              <Focus focused={focused} toggleFocus={this.toggleFocus} />
            )}
            <Title title={title} />
            <JobStatus status={status} />
          </TitleContainer>
          <ButtonContainer>
            <GoToJob url={url} />
            {!noActions && (
              <ActionButtons
                job={job}
                focused={focused}
                toggleFocus={this.toggleFocus}
              />
            )}
            <Date>{DateTime.fromISO(date).toFormat('dd.MM.yy')}</Date>
          </ButtonContainer>
        </TitleCellContainer>
        {showIds && (
          <IdCellContainer>
            <ShowIds job={job} />
            <EditContainer>
              <EditId job={job} />
            </EditContainer>
          </IdCellContainer>
        )}
      </JobContainer>
    )
  }
}

const JobContainer = styled.div`
  display: flex;
  width: 100%;
  height: 135px;
  background: ${({ index, focused }) => {
    if (focused) return backgroundColors.focused
    return index % 2 !== 0 ? backgroundColors.gray : backgroundColors.white
  }};
  border-bottom: solid 0.5px ${palette.gray};
`

const TitleCellContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: ${({ showIds }) => (showIds ? 65 : 100)}%;
  border-right: solid 0.5px ${palette.gray};
  height: 100%;
`

const IdCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 57.5%;
  border-bottom: solid 0.5px ${palette.gray};
`

const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 42.5%;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 42.5%;
  flex-direction: row;
`

const Date = styled(BoldFont)`
  margin-left: auto;
  margin-right: 25px;
`

export default Job
