import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import Button from './ui-elements/Button'

// HELPER
import { daysAgo } from '../helper/helper'

// STYLES
import { NormalFont, BoldFont } from '../styles/fonts'
import { palette } from '../styles/colors'

// ICONS
import { FaSearch } from 'react-icons/fa'

// ACTIONS
import { postError } from '../redux/actions/uiActions'
import { crawlClinic } from '../redux/actions/clinicActions'

const CrawlButton = ({
    clinic: { active, crawler, _id: clinicId, lastUpdate },
    small,
    reload,
    style,
    showLastUpdate
}) => {
    const [loading, setLoading] = useState(false)
    const userClinics = useSelector(state => state.users.userClinics)
    const admin = useSelector(state => state.users.admin)
    const dispatch = useDispatch()
    const handleCrawl = () => {
        const userClinicIds = userClinics.map(clinic => clinic.clinicId)
        if (userClinicIds.includes(clinicId) || admin) {
            dispatch(crawlClinic(clinicId, () => setLoading(prev => !prev), reload))
        } else {
            dispatch(
                postError(
                    'Die Klinik ist einem anderen User zugeteilt, wende dich an Rainer um eingetragen zu werden.'
                )
            )
        }
    }
    const disabled = !crawler || !active
    const radius = small ? 2.5 : 4
    const color = disabled
        ? palette.grayVeryDark
        : small
            ? palette.primaryVeryDark
            : palette.greenDark
    const lastCrawl = daysAgo(lastUpdate)
    return (
        <div style={{ display: 'flex' }}>
            <Button
                onClick={handleCrawl}
                disabled={disabled}
                loading={loading}
                text='Crawlen'
                small={small}
                iconSize={15}
                color={disabled ? 'gray' : small ? 'blue' : 'green'}
                style={
                    showLastUpdate && lastCrawl
                        ? { borderRadius: `${radius}px 0 0 ${radius}px` }
                        : style
                }
            >
                <FaSearch />
            </Button>
            {showLastUpdate && lastCrawl && (
                <LastUpdate color={color}>
                    {small ? (
                        <NormalFont color={color}>{lastCrawl}</NormalFont>
                    ) : (
                            <BoldFont color={color}>{lastCrawl}</BoldFont>
                        )}
                </LastUpdate>
            )}
        </div>
    )
}

const LastUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px 0 7px;
  border: 1.5px solid ${({ color }) => color};
  border-radius: 0 2.5px 2.5px 0;
  border-left: none;
`

export default CrawlButton
