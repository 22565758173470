import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import TextInput from '../../../ui-elements/TextInput'

// ACTIONS
import { setFilter } from '../../../../redux/actions/jobActions'

const SearchTitle = () => {
    const searchKeyTitle = useSelector(state => state.jobs.searchKeyTitle)
    const dispatch = useDispatch()
    const handleChange = event => {
        dispatch(
            setFilter({
                searchKeyTitle: event.target.value
            })
        )
    }

    return (
        <TextInput
            labelText='Titel durchsuchen'
            placeholder='Stellentitel...'
            width='90%'
            value={searchKeyTitle}
            onChange={handleChange}
        />
    )
}

export default SearchTitle
