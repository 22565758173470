import { DateTime } from 'luxon'

// ACTIONS
import { logout } from '../redux/actions/userActions'
import { postError } from '../redux/actions/uiActions'

export const handleHttpError = ({ response }, dispatch) => {
    if (response.status === 403) {
        dispatch(postError(response.data.message, () => dispatch(logout())))
    } else if (response.status === 500) {
        dispatch(postError(response.data.message))
    }
}

export const groupByKey = (array, key, transformValue) => {
    return array.reduce((groups, element) => {
        const value = transformValue ? transformValue(element[key]) : element[key]
        if (groups[value] === undefined) groups[value] = [element]
        else groups[value].push(element)
        return groups
    }, {})
}

export const getKey = (obj, key) => {
    try {
        return obj[key]
    } catch {
        return false
    }
}

export const dotifyNumber = n => {
    const stringedNumber = n.toString()
    const initialDigits = stringedNumber.length % 3
    let dotifyedNumber =
        initialDigits === 0 ? '' : stringedNumber.slice(0, initialDigits) + '.'
    let i = initialDigits
    while (true) {
        dotifyedNumber += stringedNumber.slice(i, i + 3)
        i += 3
        if (i < stringedNumber.length) dotifyedNumber += '.'
        else break
    }
    return dotifyedNumber
}

const getDateString = (daysOffset = null) => {
    return DateTime.local()
        .plus({ days: daysOffset ? daysOffset : 0 })
        .toISODate()
}

const getDiffDays = (date, otherDate = null) => {
    const firstDate = DateTime.fromISO(date, { zone: 'Europe/Berlin' })
    const secondDate = !otherDate
        ? DateTime.fromISO(getDateString())
        : DateTime.fromISO(otherDate, { zone: 'Europe/Berlin' })
    return Math.abs(secondDate.diff(firstDate, 'days').toObject().days)
}

export const daysAgo = date => {
    if (!date) return null
    const days = getDiffDays(date)
    if (days === 0) {
        return 'Heute'
    } else if (days === 1) {
        return 'Gestern'
    } else {
        return `Vor ${days} Tagen`
    }
}

export const sortListBy = (list, sortBy, descending) => {
    return list.sort((a, b) => {
        let fieldA, fieldB
        if (sortBy === 'lastUpdate') {
            const dateA = new Date(a.lastUpdate)
            const dateB = new Date(b.lastUpdate)
            fieldA = dateA.getTime()
            fieldB = dateB.getTime()
        } else if (sortBy === 'title') {
            fieldA = a.title.toLowerCase()
            fieldB = b.title.toLowerCase()
        } else if (sortBy === 'valmediId') {
            fieldA = Math.max.apply(Math, a.valmediId)
            fieldB = Math.max.apply(Math, b.valmediId)
        } else {
            fieldA = a[sortBy]
            fieldB = b[sortBy]
        }
        if (descending) {
            return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0
        } else {
            return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0
        }
    })
}

export const getClinicsInformation = clinics => {
    let nActive = 0
    let nCustomers = 0
    clinics.forEach(clinic => {
        if (clinic.active) nActive++
        if (clinic.customer) nCustomers++
    })
    return {
        nActive,
        nInactive: clinics.length - nActive,
        nCustomers
    }
}

export const filterClinics = ({ clinics, userPage, activeFilter, userFilter, customerFilter, searchKey, stateFilter }) => {
    const searchKeyLower = searchKey.toLowerCase()
    const checkSearchKey = value => {
        return value.toLowerCase().indexOf(searchKeyLower) !== -1
    }
    const checkUser = value => {
        if (userFilter === 'all') return true
        if (!value) return false
        return value.name === userFilter.name
    }
    const checkCustomer = value => {
        if (customerFilter === 'all') return true
        if (customerFilter === 'customer') return value
        return !value
    }
    const checkState = value => {
        if (stateFilter === 'all') return true
        return value.includes(stateFilter)
    }

    if (activeFilter === 'active') {
        if (userPage) {
            return clinics.map(clinic => {
                clinic.show =
                    checkSearchKey(clinic.name) &&
                    checkCustomer(clinic.customer) &&
                    clinic.active
                return clinic
            })
        } else {
            return clinics.map(clinic => {
                clinic.show =
                    checkSearchKey(clinic.name) &&
                    checkCustomer(clinic.customer) &&
                    checkUser(clinic.user) &&
                    checkState(clinic.states) &&
                    clinic.active
                return clinic
            })
        }
    } else {
        return clinics.map(clinic => {
            clinic.show = !clinic.active && checkSearchKey(clinic.name)
            return clinic
        })
    }
}

export const filterJobs = ({
    jobs,
    statusFilter,
    searchKeyId,
    searchKeyTitle
}) => {
    const titleKey = searchKeyTitle.toLowerCase()
    return jobs.map(job => {
        const titleCheck =
            titleKey === '' ? true : job.title.toLowerCase().indexOf(titleKey) !== -1
        const idCheck =
            job.valmediId.length === 0
                ? searchKeyId === ''
                    ? true
                    : false
                : job.valmediId.reduce((found, id) => {
                    if (found) return true
                    else if (id.toString().indexOf(searchKeyId) !== -1) {
                        return true
                    }
                    return void 0
                }, false)
        const statusCheck =
            statusFilter === null ? true : statusFilter === job.status
        job.show = titleCheck && idCheck && statusCheck
        return job
    })
}
