import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import clinicReducer from './reducers/clinicReducer'
import jobReducer from './reducers/jobReducer'
import userReducer from './reducers/userReducer'
import uiReducer from './reducers/uiReducer'

const rootReducer = combineReducers({
  clinics: clinicReducer,
  jobs: jobReducer,
  users: userReducer,
  ui: uiReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk))
