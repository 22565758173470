import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import Dropdown from '../../../ui-elements/Dropdown'

// ACTIONS
import { setFilter } from '../../../../redux/actions/clinicActions'

const options = [
  { label: 'Nur Kunden', value: 'customer' },
  { label: 'Nur Backfill', value: 'no-costumer' }
]

const FilterCustomers = () => {
  const dispatch = useDispatch()
  const customerFilter = useSelector(state => state.clinics.customerFilter)
  const activeFilter =
    useSelector(state => state.clinics.activeFilter) === 'inactive'

  const handleChange = option => {
    dispatch(
      setFilter({
        customerFilter: !option ? 'all' : option.value
      })
    )
  }

  const allClinics = customerFilter === 'all'
  const value = allClinics
    ? { label: 'Alle Klinken', value: 'all' }
    : options.find(option => option.value === customerFilter)
  return (
    <Dropdown
      options={options}
      value={value}
      onChange={handleChange}
      labelText='Kundenfilter'
      isDisabled={activeFilter}
      isClearable={!allClinics}
      isSearchable={false}
      width='90%'
      zIndex={100}
    />
  )
}

export default FilterCustomers
