import React from 'react'

// COMPONENTS
import Button from '../../../ui-elements/Button'

// ICONS
import { FaExternalLinkAlt } from 'react-icons/fa'

const GoToJob = ({ url, style }) => {
  const handleClick = () => window.open(url)
  return (
    <Button
      onClick={handleClick}
      small={true}
      color='blue'
      text='Zur Anzeige'
      style={{ margin: '0 25px 0 25px', width: '120px', ...style }}
    >
      <FaExternalLinkAlt />
    </Button>
  )
}

export default GoToJob
