import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

// COMPONENTS
import Button from '../../../ui-elements/Button'
import BaseTextInput from '../../../ui-elements/TextInput'

// ICONS
import { FaPencilAlt } from 'react-icons/fa'

// HELPERS
import { idIsValid } from '../../../../helper/validation'

// STYLES
import { palette } from '../../../../styles/colors'

// ACTIONS
import { postError } from '../../../../redux/actions/uiActions'
import {
  updateJob,
  UPDATE_CURRENT_CLINIC
} from '../../../../redux/actions/jobActions'

const EditId = ({ job: { clinicId, status, _id, valmediId } }) => {
  const dispatch = useDispatch()
  const [id, setId] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [highlight, setHighlight] = React.useState(false)
  const textInputRef = React.createRef()
  const disabled = status === -1 || status === -2

  const toggleLoading = () => setLoading(prev => !prev)

  const handleIdChange = ({ target }) => {
    highlight && setHighlight(false)
    setId(target.value)
  }

  const handleIdSubmit = () => {
    if (idIsValid(id)) {
      setId('')
      const update = { valmediId: [...valmediId, parseInt(id)] }
      if (status === 1) {
        update.status = 0
        dispatch({
          type: UPDATE_CURRENT_CLINIC,
          updates: { new_: -1, online: 1 }
        })
      }
      dispatch(updateJob({ _id, clinicId, update }, toggleLoading))
    } else {
      dispatch(
        postError(
          'Die eingegeben Valmedi-ID muss eine Zahl ungleich Null sein.',
          () => {
            textInputRef.current.focus()
            setHighlight(true)
          }
        )
      )
    }
  }

  return (
    <React.Fragment>
      <TextInput
        value={id}
        onChange={handleIdChange}
        loading={loading}
        disabled={disabled}
        highlight={highlight}
        reference={textInputRef}
        placeholder='ID Eingeben...'
      />
      <Button
        onClick={disabled ? void 0 : handleIdSubmit}
        loading={loading}
        small={true}
        text='Eintragen'
        color={disabled ? 'gray' : 'blue'}
        style={{ width: '110px', borderRadius: '0 4px 4px 0' }}
      >
        <FaPencilAlt />
      </Button>
    </React.Fragment>
  )
}

const TextInput = styled(BaseTextInput)`
  height: 26px;
  width: 120px;
  font-weight: 600;
  font-size: 12pt;
  padding: 0 7px 0 7px;
  border: solid 1.5px
    ${({ disabled }) => (disabled ? palette.grayVeryDark : palette.primary)};
  border-right: none;
  border-radius: 4px 0 0 4px;
  margin-left: 25px;
`

export default EditId
