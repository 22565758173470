import React from 'react'
import styled from 'styled-components'

// COMPONENTS
import CrawlButton from '../../CrawlButton'
import JobSummary from './item-components/JobSummary'
import GoToJobs from './item-components/GoToJobs'
import Name from './item-components/Name'
import CustomerIcon from './item-components/CustomerIcon'

// STYLES
import { backgroundColors, palette } from '../../../styles/colors'

const UserClinic = ({ clinic, index }) => {
  const { _id: clinicId, name, customer } = clinic
  return (
    <Container index={index}>
      <TitleCell>
        <CustomerIcon customer={customer} />
        <Name name={name} />
      </TitleCell>
      <ButtonCell>
        <GoToJobs clinicId={clinicId} style={{ marginRight: '25px' }} />
        <CrawlButton
          clinic={clinic}
          small
          showLastUpdate
          style={{ marginRight: '25px', width: '90px' }}
        />
        <SubContainer>
          <JobSummary clinic={clinic} style={{ marginLeft: '15px' }} />
        </SubContainer>
      </ButtonCell>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  background: ${({ index }) =>
    index % 2 !== 0 ? backgroundColors.gray : backgroundColors.white};
  border-bottom: solid 1px ${palette.grayDark};
  height: ${({ height }) => height};
`

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 36%;
  height: 100%;
  border-left: solid 0.5px ${palette.gray};
`

const ButtonCell = styled.div`
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  padding-left: 25px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`

const TitleCell = styled(ButtonCell)`
  height: 50%;
  padding-right: 25px;
  border-bottom: solid 0.5px ${palette.gray};
`

export default UserClinic
