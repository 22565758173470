import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import Dropdown from '../../../ui-elements/Dropdown'
import Checkbox from '../../../ui-elements/Checkbox'

// ACTIONS
import { setFilter } from '../../../../redux/actions/jobActions'

const options = [
  { sortBy: 'date', label: 'Datum' },
  { sortBy: 'valmediId', label: 'Valmedi-ID' }
]

const SortBy = () => {
  const dispatch = useDispatch()
  const { sortBy, descending } = useSelector(state => state.jobs.sortByFilter)

  const handleFilterChange = (option, descending) => {
    dispatch(
      setFilter({
        sortByFilter: { sortBy: option ? option.sortBy : null, descending }
      })
    )
  }

  const handleDescendingChange = (descending, sortBy) => {
    dispatch(
      setFilter({
        sortByFilter: { descending, sortBy }
      })
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '90%',
        flexDirection: 'column'
      }}
    >
      <Dropdown
        options={options}
        labelText='Sortieren'
        value={
          !sortBy ? null : options.find(option => option.sortBy === sortBy)
        }
        onChange={value => handleFilterChange(value, descending)}
        valueField='sortBy'
        placeholder='Sortieren nach...'
        isClearable
        isSearchable={false}
        width='100%'
      />
      <Checkbox
        style={{ marginTop: '10px' }}
        value={descending}
        text='Absteigend'
        onChange={value => handleDescendingChange(value, sortBy)}
      />
    </div>
  )
}

export default SortBy
