import React from 'react'

// ICONS
import { IconContext } from 'react-icons'
import { FaStar, FaRegStar } from 'react-icons/fa'

import { palette } from '../../../../styles/colors'

const CustomerIcon = ({ customer, size }) => {
  return (
    <IconContext.Provider
      value={{ size, color: customer ? palette.greenLight : palette.grayDark }}
    >
      {customer ? <FaStar /> : <FaRegStar />}&nbsp;&nbsp;
    </IconContext.Provider>
  )
}

export default CustomerIcon
