import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import AppearTransition from '../../AppearTransition'
import Map from '../../map/Map.js'

// ACTIONS
import { SET_CLINIC_STATE } from '../../../redux/actions/clinicActions'

// const { data: dkvData } = await axios.get(
//    process.env.REACT_APP_API_ROUTE + `/clinics/get-dkv-data`,
//    { headers: { Authorization: 'Bearer ' + this.props.token } }
// )

class MapPage extends Component {
   componentWillMount() {
      this.props.unsetUserPage()
   }

   render() {
      return (
         <AppearTransition>
            <div className='page'>
               <Map />
            </div>
         </AppearTransition>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      unsetUserPage: () => dispatch({
         type: SET_CLINIC_STATE, payload: { userPage: false }
      })
   }
}

export default connect(null, mapDispatchToProps)(MapPage)
