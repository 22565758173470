import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import Dropdown from '../../../ui-elements/Dropdown'

// ACTIONS
import {
  RESET_FILTERS,
  setFilter
} from '../../../../redux/actions/clinicActions'

const options = [
  { label: 'Aktiv', value: 'active' },
  { label: 'Inaktiv', value: 'inactive' }
]

const FilterActive = () => {
  const onlyActive =
    useSelector(state => state.clinics.activeFilter) === 'active'
  const dispatch = useDispatch()
  const handleChange = ({ value: activeFilter }) => {
    dispatch({ type: RESET_FILTERS })
    dispatch(setFilter({ activeFilter }))
  }
  return (
    <Dropdown
      options={options}
      value={onlyActive ? options[0] : options[1]}
      onChange={handleChange}
      isSearchable={false}
      labelText='Aktiv Filter'
      zIndex={100}
      width='90%'
    />
  )
}

export default FilterActive
