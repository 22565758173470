import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import Dropdown from '../../../ui-elements/Dropdown'

// ACTIONS
import { setFilter } from '../../../../redux/actions/clinicActions'

const FilterStates = () => {
   const dispatch = useDispatch()

   const handleChange = option => {
      dispatch(setFilter({ stateFilter: option ? option.value : 'all' }))
   }

   const stateFilter = useSelector(state => state.clinics.stateFilter)
   const value = stateFilter === 'all' ? null :
      stateOptions.find(({ value }) => value === stateFilter)
   return (
      <Dropdown
         options={stateOptions}
         value={value}
         onChange={handleChange}
         labelText='Bundesland'
         placeholder='Bundesland wählen...'
         isClearable
         isSearchable
         width='90%'
         zIndex={100}
      />
   )
}

export default FilterStates

const stateOptions = [
   {
      label: 'Baden-Württemberg',
      value: 'BW'
   },
   {
      label: 'Bayern',
      value: 'BY'
   },
   {
      label: 'Berlin',
      value: 'Berlin'
   },
   {
      label: 'Brandenburg',
      value: 'BB'
   },
   {
      label: 'Bremen',
      value: 'HB'
   },
   {
      label: 'Hamburg',
      value: 'HH'
   },
   {
      label: 'Hessen',
      value: 'HE'
   },
   {
      label: 'Mecklenburg-Vorpommern',
      value: 'MV'
   },
   {
      label: 'Niedersachsen',
      value: 'NDS'
   },
   {
      label: 'Nordrhein-Westfahlen',
      value: 'NRW'
   },
   {
      label: 'Rheinland-Pfalz',
      value: 'RP'
   },
   {
      label: 'Saarland',
      value: 'SL'
   },
   {
      label: 'Sachsen-Anhalt',
      value: 'SA'
   },
   {
      label: 'Sachsen',
      value: 'SN'
   },
   {
      label: 'Schleswig-Holstein',
      value: 'SH'
   },
   {
      label: 'Thüringen',
      value: 'TH'
   }
]
