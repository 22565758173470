import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

// COMPONENTS
import AppearTransition from '../AppearTransition'

const Modal = ({ show, children, onClose }) => {
    const content = (
        show && (
            <AppearTransition>
                <Backdrop onClick={onClose} />
                {children}
            </AppearTransition>
        )
    )
    return ReactDOM.createPortal(content, document.getElementById('modal-hook'))
}

const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 90;
`

export default Modal
