import React, { Component } from 'react'
import styled from 'styled-components'

// COMPONENTS
import RichTextEditor from 'react-rte'
import { BoldFont } from '../../styles/fonts'

class Editor extends Component {
   state = {
      editorState: RichTextEditor.createValueFromString(
         this.props.initialValue ?? '',
         'html'
      )
   }

   handleChange = value => {
      this.setState({ editorState: value })
      this.props.onChange(value.toString('html'))
   }

   render() {
      const { style, width, labelText } = this.props
      return (
         <Container width={width} style={style}>
            {labelText && <Label>{labelText}</Label>}
            <RichTextEditor
               value={this.state.editorState}
               onChange={this.handleChange}
               toolbarClassName='editor-toolbar'
               editorClassName='editor'
               toolbarConfig={{
                  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
                  INLINE_STYLE_BUTTONS: [
                     { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                     { label: 'Italic', style: 'ITALIC' },
                     { label: 'Underline', style: 'UNDERLINE' }
                  ],
                  BLOCK_TYPE_DROPDOWN: [
                     { label: 'Normal', style: 'unstyled' },
                     { label: 'Überschrift', style: 'header-three' },
                  ],
                  BLOCK_TYPE_BUTTONS: [
                     { label: 'UL', style: 'unordered-list-item' },
                     { label: 'OL', style: 'ordered-list-item' }
                  ]
               }}
            />
         </Container>
      )
   }
}

export default Editor

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ?? '100%'};
`

const Label = styled(BoldFont)`
  width: 100%;
  margin-bottom: 4px;
`
